export default {
  navigation: {
    archon: {
      home: 'Домой',
      map: 'Карта',
      dashboard: 'Контрольная панель',
      dashboards: 'Контрольные панели',
      signals: 'Сигналы',
      settings: 'Настройки',
      reporting: 'Создание отчёта',
      users: 'Пользователи',
      user: 'Пользователь',
      createUser: 'Добавить пользователя',
      assetDimensions: 'Параметры машины',
      assetDimension: 'Параметр машины',
      assets: 'Машины',
      asset: 'Машина',
      logger: 'Logger',
      loggers: 'Loggers',
      createLogger: 'Create logger',
      geofences: 'Геозоны',
      organizations: 'Организация',
      organization: 'Организация',
      subsidiaries: 'User groups',
      subsidiary: 'User group',
      sites: 'Участки разработки',
      site: 'Участок разработки',
      login: 'Авторизация',
      jobs: 'Scheduled Jobs',
      events: 'Events',
      wheels: 'Wheels',
      wheel: 'Wheel',
      tyres: 'Tyres',
      tyre: 'Tyre',
      createTyre: 'Create Tyre',
      createWheel: 'Create Wheel',
      duplicateUser: 'Duplicate User',
      listCalendars: 'Overview',
      calendars: 'calendars',
      calendar: 'calendar',
      createCalendar: 'Create calendar',
      'heavy-equipment': 'Heavy Equipment',
      job: 'job',
      createJob: 'create job',
      assetTypes: 'Типы машин',
      createAssetType: 'Create Asset Type',
      userPermissionSummary: 'User Permissions',
      ListSchedules: 'Schedules',
      EditSchedule: 'Edit Schedule',
      RescheduleJobs: 'Reschedule Jobs',
      Marketplace: 'Marketplace',
      ListProducts: 'Products',
      EditProduct: 'Product',
      CreateProduct: 'Create Product',
      shiftReports: 'Shift Reports',
      acquisitions: 'Acquisitions',
      createAcquisition: 'Acquisitions',
      acquisition: 'Acquisitions',
      OrganizationAcquisitions: 'Organization Acquisitions',
    },
    cockpit: {
      home: 'Главная страница',
      map: 'Карта',
      dashboard: 'Контрольная панель',
      dashboards: 'Контрольные панели',
      signals: 'Сигналы',
      settings: 'Настройки',
      reporting: 'Создание отчёта',
      login: 'Авторизация',
      asset: 'Профиль машины',
      organizations: 'Мои организации',
      assets: 'цифровые объекты',
      subsidiary: 'Организация',
      assetList: 'Список машин',
      calendar: 'Planning',
      privacyPolicy: 'Политика конфиденциальности',
      AssetOverview: 'Профиль машины',
      AssetLog: 'Профиль машины',
      maintenance: 'Обслуживание',
      marketplace: 'Marketplace',
      HealthExplorer: 'Обслуживание',
      serviceDealer: 'Сервис',
      ServiceDealerHealthExplorer: 'Сервис',
      Planning: 'Planning',
      historicalAssetHealth: 'Статистика состояния машины',
      HistoricalAssetHealthList: 'Статистика состояния машины',
    },
    subRoutes: {
      AssetHealthOverview: 'Обзор',
      AssetHealthInsight: 'Сведения',
      AssetHealthStatistics: 'Статистика',
      ServiceDealerAssetHealthOverview: 'Обзор',
      ServiceDealerAssetHealthInsight: 'Сведения',
      ServiceDealerAssetHealthStatistics: 'Статистика',
      HistoricalAssetHealthOverview: 'Обзор',
    },
  },
  actions: {
    create: 'Создать',
    share: 'Сохранить',
    ok: 'ок',
    apply: 'применить',
    cancel: 'отменить',
    archive: 'создать архив',
    clearAll: 'очистить всё',
    login: 'Авторизация',
    logout: 'выйти из системы',
    goTo: 'перейти',
    hideEmpty: 'спрятать пустоту',
    showDetails: 'показать детали',
    showActivityColors: 'показать цвета активности',
    backToOverview: 'вернуться к общему обзору',
    choose: 'выбрать',
    add: 'добавить',
    delete: 'удалить',
    pressEnterToSelect: 'Нажмите enter, чтобы выбрать',
    duplicate: 'Дублировать',
    unsubscribe: 'Отписаться',
    scrollToTop: 'прокрутка к верху',
    readMore: 'read more ...',
    contactUs: 'Связаться с нами',
    gotIt: 'Скрыть',
    read: 'Прочитать здесь',
    search: 'Поиск...',
    selectAll: 'выбрать все',
    deselectAll: 'снять все',
    saveFilters: 'спасти',
    moreDetails: 'Подробнее',
    goToInsights: 'Go to Insight | Go to Insights',
    goToOverviews: 'Go to Overview',
    goToSignals: 'Перейти к сигналам',
    goToAssetHealthOverview: 'Перейти к обзору состояния',
    showSettings: 'показать настройки',
    hideSettings: 'скрыть настройки',
    updateAcquisition: 'Update Acquisition',
    assignProduct: 'Assign Product',
    update: 'Обновить',
    close: 'закрывать',
    copyInformation: 'копировать информацию',
    seeRelatedSignals: 'Посмотреть связанные сигналы',
  },
  placeholders: {
    searchAssetDimensions: 'поиск доступных параметров',
    selectOption: 'Выбрать',
  },
  messages: {
    unauthorized: 'У вас нет права доступа к этой странице',
    pageNotFound: 'Страницы, которую вы запрашиваете, не существует',
    noData: 'Нет данных для отображения. Для получения дополнительной информации обратитесь к специалисту из talpasolutions.',
    notAvailable: 'Недоступно',
    noDataForSelectedTime: 'Нет информации для выбранного временного отрезка.',
    noDataWithType: 'Еще нет {type}.',
    noNotifications: 'Нет новых уведомлений.',
    newDashboard: 'Новая контрольная панель',
    errorLoadingOverlay: 'Была ошибка при загрузке верхнего слоя (оверлея)',
    loggingIn: 'вход в систему',
    emptyDashboard: 'На этой контрольной панели пока ещё нет виджетов',
    emptyDashboardAdd: 'Нажмите кнопку Инструменты внизу, чтобы добавить….',
    noSignalsSelected: 'Сигналы пока не выбраны. Нажмите на клавишу + внизу, чтобы добавить',
    comingSoon: 'Скоро будет доступно!',
    widgetNotCompatible: 'Виджет не совместим с контрольной панелью',
    errorLoadingWidgetType: "Ошибка при загрузке виджета типа '{type}'.",
    errorLoadingWidget: 'Ошибка при загрузке виджета',
    selectOneDay: 'Пожалуйста, выберите один день',
    searchNoResults: 'Нет результатов для {query}',
    notUnique: 'Указанное значение уже получено',
    required: 'Это поле требуется заполнить',
    minValue: 'Field must have at least {value} digits',
    maxValue: 'Field cannot have more than {value} digits',
    entityCreatedSuccessfully: '{entity} создан успешно.',
    entityDeletedSuccessfully: '{entity} удален успешно.',
    entityUpdatedSuccessfully: '{entity}  обновлен успешно.',
    entityAddedSuccessfully: '{entity} добавлен успешно.',
    noAdditionalEntitiesAvailable: 'Дополнительный {entity} недоступен.',
    sessionEnded: 'Ваш сеанс истек. Пожалуйста, авторизуйтесь снова.',
    maxDimensionsAllowed: 'Макс. количество доступных измерений',
    incompatibleDashboardType: 'Данный виджет отображается только в дэшборде "Одиночный Вид" (Single View)',
    hasTooManyDimensions: 'Для создания данного виджета было превышено максимальное количество измерений',
    hasTooFewDimensions: 'Для создания данного виджета необходимо выбрать дополнительные измерения',
    noDimensionsAllowed: 'Для создания этого виджета необходимо снять выделение с выбранных измерений',
    noSubsidiaries: 'Пока нет групп пользователей',
    addSubsidiary: 'Добавить группу пользователей',
    noAdditionalData: 'Нет дополнительной информации.',
    tyreNotAvailableTitle: 'Следите за состоянием своих шин',
    tyreNotAvailableMsg: 'Эта функция пока недоступна для вас. Свяжитесь с нами для получения дополнительной информации.',
    new: 'Обновления',
    notSupported: 'Этот виджет больше не поддерживается!',
    assetSelector: 'выберите хотя бы одну машину',
    noSignalData: 'Нет данных для этого сигнала',
    noDataAvailable: 'Данные недоступны.',
    noDataShort: 'No data.',
    addProduct: 'Assign a product',
    noAcquisition: 'No products assigned yet',
    productsTierNotAvailable:
      'Either the product doesnt have a tier level configured, Or the organization selected have already acquired the highest Tier of the selected product',
    clickToInteract: 'Нажмите, чтобы взаимодействовать с диаграммой.',
    minText: 'Поле должно содержать не менее {value} букв.',
    noDimensionData: 'Нет {dimension} данных за указанный период времени и/или по выбранным машинам',
    dataOutOfRange: '>3 месяцев назад',
    confirmEngineSave: 'Вы добавляете новый двигатель. Вы уверены, что хотите сохранить его?',
    confirmCancel: 'Ваши изменения будут утеряны, вы уверены, что хотите отменить?',
    noSpareParts: 'Запчастей нет!',
    maintenanceCopySuccess: 'Сведения об услуге успешно скопированы!',
    noSickPeriod: 'Нет данных о неисправностях за выбранный месяц.',
    cookieBanner:
      'Мы используем аналитику, чтобы понять, как используется наш продукт, и улучшить ваш опыт. Вы можете отказаться в любое время, нажав «Отклонить» в разделе аналитики в настройках.',
  },
  all: 'все',
  backToTop: 'наверх',
  noData: 'нет данных',
  alias: 'Псевдоним | псевдонимы',
  app: 'приложение | приложения',
  assetDimension: 'Параметр машины | Параметры машины',
  asset: 'цифровой объект | цифровые объекты',
  allAssets: 'все цифровые объекты',
  assetType: 'Типы машин',
  assetModel: 'asset Model',
  bundle: 'Bundle | Bundles',
  loggerModel: 'Logger Model',
  code: 'код | коды',
  condition: 'условие | условия',
  date: 'дата | даты',
  designation: 'обозначение | обозначения',
  dimension: 'измерение | измерения',
  column: 'Колонка | Колонки',
  row: 'Строка | Строки',
  duration: 'продолжительность | durations',
  category: 'категория | категории',
  utilizationCategory: 'категория использования | категории использования',
  email: 'электронная почта | электронные почты',
  username: 'имя пользователя | имена пользователей',
  role: 'роль | роли',
  end: 'конец | концы (?)',
  geofence: 'Местоположение',
  icon: 'иконка | иконки',
  language: 'язык | языки',
  lastUpdate: 'Последний Анализ',
  location: 'местоположение | Местоположения',
  model: 'модель| Модели',
  member: 'пользователь | пользователи',
  name: 'имя | имена',
  now: 'сейчас',
  oem: 'Производители(OEMs)',
  organization: 'организация | организации',
  overview: 'общий обзор | общие обзоры',
  picture: 'pисунок| рисунки',
  profile: 'профиль | profiles',
  setting: 'настройка | настройки',
  severity: 'строгость | строгости',
  site: 'участок| Участки',
  unknownSite: 'неизвестный участок| неизвестные участки',
  start: 'начало | starts',
  status: 'текущее состояние | statuses',
  schedule: 'План | План',
  totalMachineHours: 'Моточасы',
  sum: 'сумма | суммы',
  talpaID: 'Talpa идентификатор | Talpa идентификаторы',
  technicalID: 'технический идентификатор',
  targetValue: 'искомое значение | искомые значения',
  time: 'время | times',
  title: 'название | названия',
  total: 'итог | итоги',
  type: 'тип | типы',
  unit: 'единица | единицы',
  user: 'пользователь | пользователи',
  owner: 'owner | owners',
  subsidiary: 'группа пользователей | группы пользователей',
  widget: 'виджет | виджеты',
  myAccount: 'мой аккаунт | мои аккаунты',
  myOrganizations: 'Моя организация | Мои организации',
  warning: 'предупреждение | предупреждения',
  error: 'ошибка | ошибки',
  overall: 'общее',
  job: 'job | jobs',
  event: 'event | events',
  calendar: 'calendar | calendars',
  utilizationInShift: 'Задействованность в смене',
  tyre: 'Шина | Шины',
  serialNumber: 'Серийный номер | serial numbers',
  wheel: 'wheel | wheels',
  health: 'Состояние',
  Health: 'Статус',
  machineHealth: 'Статус машин',
  healthStatus: 'Техническое состояние',
  errorLog: 'журнал ошибок | журналы ошибок',
  logger: 'Logger',
  loggers: 'Loggers',
  lastLocation: 'Последнее местоположение',
  integratedSince: 'Интегрировано с',
  manufacturer: 'Производитель',
  currentLocation: 'Текущее местоположение',
  product: 'Product | Products',
  tier: 'Tier | Tier',
  machineHealthLabels: {
    noFaults: 'Неисправностей не обнаружено',
    noWarningsErrors: 'Предупреждений и ошибок в течении последнего обновления статуса машины не обнаружено',
    moreCategories: 'еще {x}',
  },
  assetHealthStatus: {
    Green: 'OK',
    Yellow: 'Предупреждение',
    Red: 'Ошибка',
    Unknown: 'Недоступно',
  },
  membershipRoles: {
    user: 'пользователь',
    admin: 'администратор',
  },
  itIsYou: 'Это вы!',
  warnings: 'Предупреждения',
  languages: {
    de: 'Deutsch',
    en: 'English',
    ru: 'Русский',
  },
  uiSettings: {
    units: {
      METRIC: 'метрические единицы',
      IMPERIAL: 'единицы британской системы мер и весов',
    },
    time: {
      AM_PM_FORMAT: '12-часовой формат',
      TWENTYFOUR_FORMAT: '24-часовой формат',
    },
    defaultApp: {
      MAINTENANCE_APP: 'Обслуживание приложение',
      SD_APP: 'Сервис приложение',
    },
    analytics: {
      OPT_IN: 'Принять отслеживание',
      OPT_OUT: 'отслеживание отклонения',
    },
  },
  types: {
    user: 'Пользователь | Пользователи',
    dashboard: 'Контрольная панель | Контрольные панели',
    signal: 'Сигнал | Сигналы',
    report: 'Отчёт | Отчёты',
  },
  dashboard: {
    time: {
      calendarWeek: 'КН',
      today: 'Сегодня',
    },
    errors: {
      titleExists: 'Название дэшборда уже занято',
      createError: 'Для создания дэшборда для парка машин необходимо выбрать, как минимум, две машины',
    },
    types: {
      placeholder: 'Вид',
      placeholderFilter: 'Фильтр',
      fleet: ' Парк машин',
      single_asset: 'Одиночный вид',
      template_single_asset: 'Шаблон по умолчанию',
      template_fleet: 'Образец формы для одной машины',
    },
    filters: {
      NONE: 'Отсутствует',
      MANUAL: 'настройка',
      ASSET_TYPE: 'тип машины',
      MODEL: 'модель',
      OEM: 'Производители',
      SITE: 'участок',
      USER_GROUP: 'группа пользователей',
    },
    attributes: {
      date_range: 'промежуток времени',
      writing_access: 'доступ с правами записи',
      sharedWith: 'общение с',
      allUsers: 'все пользователи',
      shareDashboardWith: 'Поиск пользователей ...',
      noUsersFound: 'Пользователи не найдены',
    },
    shift: 'Смена | Смены',
    shiftsView: 'Показать смены',
    shiftSelect: 'Пожалуйста, выберите график смен',
    shiftsNotAvailable: 'Для выбранных машин нет доступных графиков смен',
    shiftSystem: 'График смен',
    shiftName: 'Смена',
    shiftTimes: 'Время',
    shiftStart: 'начало смены',
    shiftEnd: 'конец смены',
    dashboardSettings: 'Настройки панели',
    owner: 'Владелец',
    dashboardSharedMessage: 'Вы поделились этой панелью',
    all: 'Bce',
    sharedByYou: 'Вы поделились',
    sharedByOther: 'С вами поделились',
    tools: {
      tool: 'Инструменты | Инструменты',
      addWidget: 'Добавить виджет',
      editWidget: 'Редактировать виджеты',
      copyDashboard: 'Дублировать эту контрольную панель',
      shareDashboard: 'Поделиться этой панелью',
    },
  },
  map: {
    assetSelector: {
      placeholder: 'все машины',
    },
    legend: 'Легенда',
    average: 'Среднее',
    max: 'Максимум',
    mapSettings: 'Map settings',
    geofences: 'Геозоны',
    heatmap: 'Тепловая карта',
    info: 'Пожалуйста, сначала выберите значение параметра в разделе "тепловая карта"',
    satelliteStyle: 'вид со спутника',
    messages: {
      zoomInForHeatMap: 'Приблизьте, чтобы просмотреть теплокарту',
      generatingHeatMap: 'Генерация теплокарты',
      generatingGeofences: 'Генерация Геозоны',
      allMachinesNoLocation: 'Данные о местоположении отсутствуют для всех выбранных машин',
      someMachinesNoLocation: 'Данные о местоположении отсутствуют для некоторых выбранных машин',
      selectedMachinesNoHeatMap: 'Данные теплокарты не доступны для выбранных машин',
    },
  },
  titles: {
    addDashboard: 'Создать новую контрольную панель',
    editDashboard: 'Редактировать контрольную панель',
    shareDashboard: 'Контрольные панели поделиться',
    addWidget: 'Добавить виджет',
    editWidget: 'Редактировать виджет',
    pleaseConfirm: 'Пожалуйста, подтвердите',
    availableDimensions: 'Доступные показатели деятельности',
    configureDimensions: 'Настроить показатели деятельности',
    widgetType: 'Тип виджета',
    widgetOptions: 'Параметры виджета',
    widgetPreview: 'Просмотр виджета',
    showPreview: 'Показать превью',
    mostRecent: 'самый последний',
    mostUsed: 'самый используемый',
    sessionEnded: 'сеанс истек',
    createAcquisition: 'Create Acquisition',
    addCalendar: ' Создать новый календарь',
    editCalendar: 'Редактировать календарь',
    addSchedule: 'Создать новый план',
    editSchedule: 'Edit Schedule',
  },
  createdAt: 'создано',
  updatedAt: 'последняя правка',
  runAt: 'run at',
  dashboardType: 'Тип контрольной панели',
  noOfAssets: 'Количество машин',
  noOfSharedUsers: 'Количество совместных пользователей',
  wizard: {
    steps: {
      dimensions: 'измерения',
      widget: 'визуализации',
      general: 'general',
    },
    dimensions: {
      addDimension: 'Добавить измерение',
      noneAvailable: 'Недоступно',
    },
  },
  widgetTypes: {
    TABLE: 'Таблица',
    MULTI_CHART: 'Множественный граф',
    PIE_CHART: 'Секторная диаграмма',
    MAP: 'Карта',
    SANKEY_CHART: 'Диаграмма Сэнки',
    TIMELINE_CHART: 'Хронологическая таблица',
    ASSET_INFORMATION: 'Информация по цифровому объекту',
    ERROR_LOG: 'Журнал регистрации ошибок',
    MULTI_DIMENSION: 'Многомерный',
    DRILLING_TABLE: 'Таблица для бурения',
    CYCLE_TABLE: 'Таблица рейсов',
    CARD: 'открытка',
    HISTOGRAM: 'Гистограмма',
    ERROR_STATISTICS: 'Статистика ошибок',
    DASHBOARD_INFORMATION: 'Информация на приборной панели',
    PIVOT_TABLE: 'сводная таблица',
  },
  graphTypes: {
    placeholder: 'тип графика',
    LINE: 'линия',
    COLUMN: 'столбец',
  },
  selects: {
    asset: 'выбрать машину| выбрать машины',
    general: 'Выбрать опцию',
    dimension: 'выбрать параметр',
    activities: 'выбрать мероприятия',
    type: 'выбрать тип',
    model: 'выбрать модель',
    oem: 'выбрать Производители',
    site: 'выбрать Участок разработки',
    organization: 'Select Organization',
    product: 'Select Product',
    productTier: 'Select Product Tier Level',
    acquisition: 'Select Acquisition type',
    usergroup: 'Выберите группу пользователей',
  },
  assetTypes: {
    roadheader: 'Проходческий комбайн',
    scaler: 'Кровелеоборщик',
    loadhauldump: 'ПДМ',
    surfacedumper: 'Карьерный самосвал',
    tractor: 'Трактор',
    fueltanktruck: 'Бензовоз',
    undergrounddumper: 'Подземный самосвал',
    testingtype: 'Тестовая машина',
    shuttlebus: 'Автобус для персонала',
    locomotive: 'Локомотив',
    highwaytruck: 'Шоссейный самосвал',
    drillrig: 'Буровой станок',
    loader: 'Колесный погрузчик',
    roofbolter: 'Анкероустановщик',
    plant: 'Обогатительная фабрика',
    largeholeauger: 'Врубовая машина',
    dozer: 'Бульдозер',
    continuousminer: 'Выемочный комбайн',
    bolterminer: 'Комбайн bolter miner',
    excavator: 'Экскаватор',
    wheelbasedexcavator: 'Wheel Based Excavator',
    ropeexcavator: 'Rope Excavator',
    surfacedrillrig: 'Поверхностный бурстанок',
    streetsweeper: 'метельщик улиц',
    roadroller: 'Road Roller',
    conveyor: 'Conveyor',
    crusher: 'Crusher',
    recycler: 'Ресайклер',
    compactor: 'Каток',
    coldmilling: 'Холодная фреза',
    paver: 'Асфальтоукладчик',
    materialhandler: 'Манипулятор',
    wastehandler: 'Утилизатор',
    cleanerloader: 'загрузчик-очиститель',
    compressor: 'компрессор',
    concretepump: 'бетононасос',
    towtractor: 'трактор-тягач',
    forklift: 'вилочный погрузчик',
    loadingtransporter: 'багажный тягач',
    trackconstructiontrain: 'путеукладчик',
    highpressurepump: 'высоконапорный насос',
    utilitytruck: 'вспомогательная машина',
    wastedumptruck: 'мусоровоз',
    electrictowingtractor: 'электрический тягач',
    straddlecarrier: 'портальный погрузчик',
    mobilescreen: 'мобильный грохот',
    tegelträger: ' сталевоз',
    hybridantrieb: 'гибридный привод',
    telescopichandler: 'телескопический погрузчик',
  },
  reporting: {
    report: {
      title: 'ОТЧЕТ О ПАРКЕ МАШИН',
      unit: 'Единица',
      totalAssets: 'Все машины',
      downloadDate: 'Download Date',
      timeFormat: 'Формат времени',
      datePicker: 'Дата загрузки',
      selectedTime: 'Сообщить о выбранном времени',
      loadingText: 'Пожалуйста, подождите, ваш отчет готовится...',
    },
    table: {
      assetDimensions: 'KPI',
      deviation: 'Отклонение',
    },
    csv: {
      days: 'Дни',
      startDate: 'Время начала',
      endDate: 'Время окончания',
    },
    sections: {
      utilization: 'Использование',
      production: 'Производство',
      fuel_consumption: 'Потребление топлива',
      errors: 'Ошибки',
      summary: 'Краткое содержание',
    },
    stepper: {
      next: 'Следующий',
      back: 'Назад',
      finish: 'Загрузить',
      stepOne: {
        title: 'Дата',
        subtitle: 'Отчетный период',
        types: {
          daily: 'День',
          weekly: 'Неделя',
          monthly: 'Месяц',
          yearly: 'Год',
          calendarWeek: 'КН',
        },
        monthPlaceHolder: 'Январь',
        disabled: 'отключен',
      },
      stepTwo: {
        title: 'Разделы отчета',
        subtitle: '',
      },
      stepThree: {
        title: 'Загрузить',
        subtitle: 'Тип загрузки',
        selectedRange: 'Выбранный диапазон',
        selectedSections: 'Выбранные разделы',
        downloadReportType: 'Загрузить тип отчета',
      },
    },
  },
  confirm: {
    delete: {
      Widget: 'Вы действительно хотите удалить этот виджет?',
      Dashboard: 'Вы действительно хотите удалить эту контрольную панель ',
      Dashboard_share: 'Вы действительно хотите отписаться от этой контрольной панели?',
      AssetDimension: "Вы действительно хотите удалить параметр машины '{name}'?",
      Asset: " Вы действительно хотите удалить машину '{name}'?",
      Site: "Вы действительно хотите удалить участок '{name}'?",
      Organization: "Вы действительно хотите удалить организацию '{name}'?",
      User: "Вы действительно хотите удалить пользователя '{name}'?",
      Subsidiary: 'Вы правда хотите удалить филиал?',
      Membership: 'Вы действительно хотите отказаться от этой подписки?',
      Schedule: 'Do you really want to delete this entry?',
      ShiftReport: 'Do you really want to delete this entry?',
      Job: 'Do you really want to delete this job?',
      signal: {
        card: 'Вы действительно хотите удалить эту карточку?',
      },
      wheelPosition: 'Хотите ли вы заменить или удалить данное колесо?',
      tyreUnmount: 'Хотите ли вы заменить или удалить шину?',
      ConfidenitalClient: 'Do you really want to delete this confidential client?',
      Acquisition: 'Do you really want to delete the Acquisition',
      Product: "Do you really want to delete the product '{name}'.",
      AssetWithInSubsidiary: "Вы пытаетесь удалить машину, которая входит в состав '{name}'. Вы уверены, что хотите удалить ее?",
    },
    edit: {
      subsidiary: 'Редактирование названия филиала',
    },
  },
  times: {
    timeAgo: '{count} {type} назад',
    invalid: 'недействительная временная метка',
    millisecond: 'миллисекунда | миллисекунды',
    second: 'секунда | секунды',
    minute: 'минута | минуты',
    hour: 'час | часы',
    day: 'день | дни',
    week: 'неделя | недели',
    month: 'месяц | месяцы',
    year: 'год | годы',
    timesAgo: {
      millisecond: 'миллисекунда | миллисекунды',
      second: 'секунда | секунды',
      minute: 'минута | минуты',
      hour: 'час | часы',
      day: 'день | дни',
      week: 'неделя | недели',
      month: 'месяц | месяцы',
      year: 'год | годы',
    },
  },
  signals: {
    headers: {
      search: 'доступные сигналы',
      controls: 'выбранные сигналы',
      timelines: 'выбор времени',
      currentSignalValue: 'Текущие значения сигналов',
    },
    search: {
      placeholder: 'введите здесь, чтобы выполнить отбор данных',
    },
    tooltip: {
      name: 'Название',
      defaultName: 'имя по умолчанию',
      type: 'Тип',
      unknown: 'неизвестно',
      unit: 'Единица',
      firstDataReceived: 'Первые полученные данные',
      lastDataReceived: 'Последние полученные данные',
    },
    aggregation: {
      label: 'aggregation',
      placeholder: 'select aggregation',
      enums: {
        first: 'first value',
        min: 'minimum',
        max: 'maximum',
        avg: 'average',
        count: 'count',
        sum: 'sum',
        last: 'last value',
      },
    },
  },
  shifts: {
    selectPlaceholder: 'выберите план смен',
    errors: {
      interval_error: 'Интервал между начальным и конечным временем должен быть как минимум {minutes} минут',
    },
  },
  timezone: {
    timezone: 'Часовой пояс',
    browser: {
      title: 'Часовой пояс браузера',
      description: 'Часовой пояс, в котором в настоящее время работает ваша система',
    },
    asset: {
      title: 'Часовой пояс машины',
      description: 'Часовой пояс, в котором находится каждая машина',
    },
    UTC: {
      title: 'Часовой пояс UTC',
      description: 'Время в координированном всемирном времени',
    },
    selectTimezonePlaceholder: 'Выбрать часовой пояс',
    browserTime: 'время браузера',
  },
  activityTypes: {
    ANCHORING: 'крепление анкера',
    AUXILIARY_WORK: 'дополнительная работа',
    DIESEL_IDLE: 'холостой ход дизельного двигателя',
    HYDRAULIC_IDLE: 'холостой ход гидравлического двигателя',
    ELECTRIC_IDLE: 'электрический холостой ход',
    DRILLING: 'бурение',
    DRIVING_LOADED: 'движение загруженным',
    DRIVING_UNLOADED: 'движение порожним',
    ELECTRICAL_SYSTEM_ON: 'электрическая система включена',
    HOLE_SETUP: 'постановка на скважину',
    IDLE_LOADED: 'простой загруженным',
    IDLE_UNLOADED: 'простой порожним',
    IDLE: 'простой',
    LOADING: 'загрузка',
    MACHINE_LOADING: 'загрузка машины',
    MACHINE_OFF: 'машина выключена',
    MACHINE_UNLOADING: 'разгрузка машины',
    MANEUVERING: 'маневрирование',
    MOVING: 'движение',
    PREPARATION_WORK: 'подготовительная работа',
    PUSH: 'толкание',
    QUEUE_BEFORE_DUMP: 'ожидание разгрузки',
    QUEUE_BEFORE_LOAD: 'ожидание погрузки',
    REPOSITIONING: 'переустановка',
    RETURN: 'возвращение',
    SCALING: 'оборка кровли',
    SPOTTING: 'установка под погрузку',
    STANDING: 'остановка',
    SUPPORT_LEG_WARNING: 'предупредительная сигнализация опорных стоек',
    TRANSITIONAL_DELAY: 'промежуточная задержка',
    TRAVELING: 'движение',
    UNLOADING: 'разгрузка',
    WITHDRAWING_BOOM: 'извлечение стрелы',
    WORK: 'работа',
    WAITING: 'Ожидание погрузки',
    NO_DATA: 'нет данных',
    ROCK_CRUSHING: 'Разрушение горных пород',
    ROD_CHANGE: 'замена штанги',
    SWEEPING: 'Подметать',
    ROD_REMOVAL: 'Извлечение штанги',
  },
  timeScopes: {
    lastHour: 'Последний час',
    lastSixHours: 'Последние 6 часов',
    lastDay: 'Последние 24 часа',
    lastWeek: 'Прошлая неделя',
  },
  drillingTable: {
    cycle: 'ID цикла',
    holeID: 'ID отверстия',
    asset: 'Источник',
    start: 'Начало',
    drilledDepth: 'Пробуренная глубина',
    drilledLength: 'Пробуренная длина',
    location: 'Местоположение',
    drilledDuration: 'Время бурения',
  },
  multiDimension: {
    time: 'Эволюция с течением времени',
    comparison: 'сравнение машин',
  },
  multiChart: {
    warning: 'found 4 dimensions, but MultiChart just supports 3',
  },
  sankeyChart: {
    cycle: 'рейс',
  },
  sankeyChartTable: {
    title: 'Диаграмма Таблица',
    sourceGeofence: 'исходная геозона',
    targetGeofence: 'целевая геозона',
    materialType: 'тип материала',
    noCycles: 'циклы',
    tonnage: 'тоннаж',
    sumDistance: 'расстояние',
    avgDistance: 'среднее расстояние (∅)',
    sumDuration: 'длительность',
    avgDuration: 'длительность (∅)',
    tonnageKilometer: 'тоннаж топлива на километр',
    fuelPerTonnage: 'потребление топлива на одну тонну',
    fuelPerHour: 'потребление топлива за один час',
    energyConsumptionPerTonnage: 'номинальное энергопотребление на тонну',
    energyConsumptionPerHour: 'номинальное энергопотребление в час',
    sourceAsset: 'исходная машина',
    targetAsset: 'целевая машина',
    asset: 'Машина',
  },
  histogramWidgetSettings: {
    histogram: 'гистограмма',
    class: 'класс',
    thresholds: 'предельная величина',
    default_setting_by_the_system: 'настройка по умолчанию',
    set_bin_sizes: 'Задать фиксированный размер столбца гистограммы',
    choose_number_of_bars: 'выбрать определенное количество столбцов',
    type_the_value: 'ввести величину',
    max_value: 'максимальное значение',
    min_value: 'минимальное значение',
    error: {
      negativeError: 'Разница пороговых значений должна быть выше нуля',
      binSizeError: '(Разница в пороговых значениях/размер столбца) должны быть целым числом',
      noOfBinError: 'Количество столбцов должно быть 3 или более',
      naNError: 'Неверный формат данных. Пожалуйста, вводите только числа',
      binNotInteger: 'Неверный формат данных. Пожалуйста, вводите только целые числа',
      invalidInput: 'Неверный формат данных.',
    },
  },
  nonAdminUsersCanSeeOtherUsers: 'Пользователи, не являющиеся администраторами, могут видеть других пользователей (username and email).',
  dashboardSharingForAllUsers: 'Пользователи могут делиться своими контрольными панелями',
  errorTable: {
    severity: {
      yellow: 'жёлтый',
      red: 'красный',
    },
    recommendedAction: 'Pекомендуемое действие',
    lastDay: 'Последние активные 24 часа',
    lastHour: 'Последний активный час',
    active: 'В конце последнего анализа',
    lastWeek: 'Последние 7 дней',
  },
  activityTable: {
    asset: 'Машина',
    start: 'Начало',
    end: 'Конец',
    type: 'Тип',
    duration: 'Продолжительность (MIN)',
    tableIcon: 'Табличный вид',
  },
  errorStatistics: {
    allMachineMessages: 'Ошибки всех машин',
    red: 'Неисправности',
    yellow: 'Предупреждения',
    faults: 'Неисправности',
    warnings: 'Предупреждения',
    errorCount: 'количество уведомлений',
    errorDuration: 'продолжительность уведомления',
    evolutionOverTime: 'Изменение по времени',
    machineComparison: 'Сравнение машин',
    categoryComparison: 'Сравнение категорий',
    severity: 'тяжесть',
  },
  userGuide: {
    title: 'Познакомьтесь с нашим новым управлением пользователями',
    content: 'мы представляем нашу новую функцию управления пользователями для нашей системы',
    check_it_out: 'проверить это',
    learn_more: 'учить больше',
  },
  releaseNotes: {
    productName: 'Release notes',
    version: 'версия 14.0',
    title: 'Release notes 14.0 доступно!',
    content: 'Мы представляем новую версию GHH inSiTE',
    learn_more: 'узнать больше',
  },
  feed: {
    profile: {
      morning: 'Доброе утро',
      afternoon: 'Добрый день',
      evening: 'Добрый вечер',
      info: 'Здесь вы видите события ваших операций',
    },
    dashboard: {
      title: 'моя контрольная панель',
      no_dashboards: 'нет доступных контрольных панелей',
      create: 'создать',
      toolTip: 'всего контрольных панелей',
      popover: {
        title: 'Пока что нет доступных контрольных панелей',
        info: 'Свяжитесь с вашим администратором, что бы получить доступ к контрольным панелям',
      },
    },
    asset: {
      title: 'мои машины',
      noAssets: 'у вас нет доступа к этой машине',
      toolTip: 'всего машин',
      search: {
        placeholder: 'поиск машин',
        noAssets: 'ни одной машины не найдено',
      },
      filter: {
        options: {
          all: 'показать все',
          warnings: 'машины с предупреждениями',
          errors: 'машины с ошибками',
        },
      },
      popover: {
        title: 'невозможно найти вашу машину',
        info: 'свяжитесь с вашим администратором, что бы получить доступ к вашим машинам',
      },
    },
    category: {
      all: 'все',
      production: 'производство',
      maintenance: 'поддержка',
      safety: 'безопасность',
      hardware: 'аппаратное обеспечение',
      information: 'Информация',
    },
    subsidiaries: 'групп пользователей',
    filter: {
      title: 'фильтр',
      clear: 'очистить',
      options: {
        anytime: 'любое время',
        lastday: 'последний день',
        lastweek: 'последняя неделя',
        lastmonth: 'последний месяц',
        lastyear: 'последний год',
      },
      label: {
        anytime: 'любое время',
        lastday: 'последний день',
        lastweek: 'последняя неделя',
        lastmonth: 'последний месяц',
        lastyear: 'последний год',
      },
    },
    remove: 'удалить',
    delete: 'вы удалили ленту случайно?',
    undo: 'отменить',
    getHelp: 'получить помощь',
    card: {
      name: 'card',
      search: 'search...',
      cards: 'карточки',
      noData: 'параметры недоступны для вас',
      title: 'Ваша эффективность',
      warning: 'Вы можете выбрать только 5 карточек одновременно. Для добавления новых, удалите несколько карточек.',
      addCard: 'добавить карточку',
      addCardHere: 'Нажмите здесь, чтобы добавить новую карточку',
      options: {
        lastDay: 'последние 24 часа',
        lastWeek: 'последние 7 дней',
        lastMonth: 'последние 30 дней',
      },
      popover: {
        title: 'не удалось найти ни одного параметра машины',
        info: 'свяжитесь с вашим администратором, чтобы получить доступ к параметрам машины',
      },
    },
    feedEndMain: 'снова вверх',
    feedEndInfo: 'нет другой ленты',
    stayTunedInfo:
      'Это только начало! Взволнован, что будет дальше? Оставайся с нами, чтобы поскорее обрести больше оперативных сведений в этой области',
    listItemActions: {
      remove: 'удалить',
    },
    insightsAvailable: 'Показатели доступны',
    healthStatus: 'Техническое состояние',
  },
  feedItems: {
    SHIFT_REPORT: 'shift report | shift reports',
    TYRES_HEALTH_REPORT: `Сводка по шинам вашего автопарка`,
  },
  MachineAssignmentEvents: {
    titles: {
      banner: 'У вас есть доступ к новой машине',
      bannerNoData: 'свяжитесь с администратором Talpa для дополнительной информации',
      in: 'в',
      of: '',
    },
  },
  PerformanceGoalEvents: {
    goalAchieved: 'Ваши плановые показатели <i>{x}</i> были достигнуты',
    goalNotAchieved: 'Ваши плановые показатели <i>{x}</i> не были достигнуты',
    unit: 'Единица',
    assetDimension: 'Название KPI',
    achieved: 'Достигнуто',
    targetValue: 'Целевое значение',
    change: 'Изменение (%)',
  },
  shiftReportEvent: {
    titles: {
      banner: 'Данный отчет по смене основан на последнем анализе, проведенном {date} в {time}',
      bannerNoData:
        'Не удалось получить данные из-за отсутствия данных из анализа. Пожалуйста, свяжитесь с администратором Talpa для дополнительной информации',
      insights: '{shiftName} показатели - ',
      production: 'производство',
      drilling: 'бурение',
      auxiliary: 'вспомогательный',
    },
    tableHeader: {
      productionAssets: 'Добычная техника',
      drillingAssets: 'Буровая техника',
      auxiliaryAssets: 'Вспомогательная техника',
    },
    shiftGeneralInformation: {
      date: 'Дата',
      reportCreationTime: 'Время создания отчета',
      shiftTimePeriod: 'Длительность смены',
      lastCompleteData: 'Последние полные данные',
      noActiveFailure: 'Нет активных сообщений об ошибках',
      fuelUsed: 'потребление топлива',
      dataOutOfRange: 'Последние известные даты >7 дней назад',
      endOfShift: 'конец смены',
    },
    noData: 'Данные пока не доступны',
  },
  tyresHealthReportEvent: {
    pressureTableHeader: 'В течение последней смены давление в шинах следующих машин было неправильным',
    temperatureTableHeader: 'В течение последней смены у следующих машин перегрелись шины',
    bottomLink: {
      text1: 'Проверьте текущую информацию в',
      link: 'списке машин',
      text2: '',
    },
  },
  plan: {
    showAs: 'Показать как',
  },
  tyres: {
    name: 'Имя',
    position: 'Положение',
    airPressure: 'Давление',
    temperature: 'Температура',
    tyreHealth: 'Состояние шин',
    maintenance: 'Техническое обслуживание',
    axelPosition: {
      11: 'Переднее левое',
      12: 'Переднее правое',
      21: 'Заднее левое наружное',
      22: 'Заднее левое внутреннее',
      23: 'Заднее правое внутреннее',
      24: 'Заднее правое наружное',
    },
    toolTip: {
      highTemperature: 'Охладите эту шину',
      highPressure: 'Слишком высокое давление',
      lowPressure: 'Накачайте эту шину',
      noSignal: 'Сигнал недоступен',
    },
  },
  privacyPolicy: {
    bannerMessage: 'Мы обновили нашу политику конфиденциальности',
    menu: 'политика конфиденциальности',
    accept: 'Ознакомиться и принять',
  },
  table: {
    sorting: {
      A_TO_Z: {
        asc: 'От А до Я',
        desc: 'От Я до А',
      },
      NEW_TO_OLD: {
        asc: 'Новое к старому',
        desc: 'Старое к новому',
      },
      GOOD_TO_CRITICAL: {
        asc: 'ОК к Ошибкe',
        desc: 'Ошибка к ОК',
      },
      OK_TO_ERROR: {
        asc: 'ОК к Ошибкe',
        desc: 'Ошибка к ОК',
      },
      LOW_TO_HIGH: {
        asc: 'Низкое к Высокому',
        desc: 'Высокое к Низкому',
      },
      FRONT_TO_BACK: {
        asc: 'Перед - Зад',
        desc: 'Зад - Перед',
      },
    },
    filter: {
      successMessage: 'Ваши настройки сохранены. Сохраненные фильтры и сортировка будут предварительно применены к этой таблице. ',
      deleteInfoMessage: 'Вы уверены, что хотите удалить сохраненный фильтр и настройки сортировки этой таблицы?',
      deleteSuccessMessage: 'Ваши сохраненные настройки были удалены! ',
      tooltip: {
        save: 'сохранить фильтры',
        reset: 'очистить фильтры',
        delete: 'Удалить уже сохраненные фильтры',
        close: 'Закрыть фильтрующий лоток',
        copy: 'Copy results to clipboard',
      },
    },
    copy: {
      successMessage: '{count} rows copied to clipboard',
      errorMessage: 'Error copying rows to clipboard',
    },
  },
  reset: 'Сбросить',
  molecules: {
    assetEngine: {
      noEngineData: 'Данные двигателя отсутствуют',
      aggregators: 'МИН/СРЕД/МАКС',
      filling_load_levels: 'УРОВЕНЬ НАПОЛНЕНИЯ/НАГРУЗКИ',
      temperatures: 'ТЕМПЕРАТУРЫ',
      pressures: 'СЧЕТЧИКИ',
      counters: 'ДАВЛЕНИЯ',
      signals: {
        engine__1_engine_coolant_level_1: 'Охлаждающая жидкость',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_volume: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_level: 'AdBlue',
        engine__1_aftertreatment_1_diesel_particulate_filter_soot_load_percent: 'Сажевая нагрузка',
        engine__1_aftertreatment_1_diesel_particulate_filter_ash_load_percent: 'Зольная нагрузка',
        engine__1_engine_oil_life_remaining: 'Остаточный ресурс масла',
        engine__1_engine_oil_level: 'Моторное масло',
        engine__1_engine_intake_manifold_1_temperature: 'Впускной коллектор',
        engine__1_engine_exhaust_temperature: 'Выхлоп двигателя 1',
        engine__1_engine_exhaust_temperature_2: 'Выхлоп двигателя 2',
        engine__1_engine_coolant_temperature: 'Охлаждающая жидкость',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_temperature_1: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_scr_intake_temperature: 'Впуск SCR',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_intake_temperature: 'Впуск катализатора окисления ДТ',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_outlet_temperature: 'Выпуск катализатора окисления ДТ',
        engine__1_ambient_air_temperature: 'Окружающий воздух',
        engine__1_engine_oil_temperature_1: 'Моторное масло',
        exhaust_emission_controller_aftertreatment_1_exhaust_temperature_1: 'Выхлоп после обработки',
        engine__1_engine_intake_manifold__1_pressure: 'Впускной коллектор',
        engine__1_engine_air_filter_1_differential_pressure: 'Разница давления воздушного фильтра',
        engine__1_engine_fuel_delivery_pressure: 'Подача топлива',
        engine__1_engine_oil_pressure_1: 'Давление Масла',
        engine__1_aftertreatment_1_diesel_exhaust_fluid_doser_1_absolute_pressure: 'Дозатор жидкости выхлопных газов',
        engine__1_aftertreatment_1_diesel_particulate_filter_outlet_pressure: 'Выходное давление сажевого фильтра',
        engine__1_aftertreatment_1_diesel_particulate_filter_differential_pressure: 'Разница давления сажевого фильтра',
        engine__1_engine_intake_air_pressure: 'Впуск двигателя',
        engine__1_engine_oil_filter_differential_pressure: 'Разница давления масляного фильтра',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_differential_pressure: 'Разница давления дизельного катализатора окисления',
        engine__1_barometric_pressure: 'Барометрическое давление',
        engine__1_engine_total_hours_of_operation: 'Моточасы',
        engine__1_engine_total_fuel_used: 'Общее количество использованного топлива',
        engine__1_aftertreatment_1_diesel_particulate_filter_time_since_last_active_regeneration:
          'Сажевый фильтр время с момента последней активной регенерации',
        engine__1_engine_fuel_supply_estimated_remaining_distance: 'Дальность хода (топливо)',
      },
    },
  },
  label: 'Обозначение',
  description: 'Описание',
  engine: 'ДВИГАТЕЛЬ',
  timelinechart: {
    relative: 'Относительный вид',
    absolute: 'Абсолютный вид',
    filterEmpty: 'Скрыть дни без каких-либо данных',
    showDetails: 'Показать данные по часам',
    tableView: 'Таблица',
    chartView: 'Диаграмма',
  },
  maintenance: {
    district: 'Район',
    fleetDetails: 'Подробности о парке машин',
    fleetComposition: 'Состав парка машин',
    fleet: 'Парк машин | Парки машин',
    shiftPlan: 'План смены | Планы смен',
    shift: 'Смена',
    selectFleet: 'Выбрать парк машин',
    selectShiftPlan: 'Выбрать план смены',
    selectShift: 'Выбрать смену',
    selectShiftOccurance: 'Выберите случай смены',
    assetRequiresAttention: 'Машина с критическими проблемами | Машинаы с критическими проблемами',
    assetWithErrors: 'Машина с сообщениями о неисправностях | Машинаы с сообщениями о неисправностях',
    assetWithWarnings: 'Машина с предупреждающими сообщениями | Машинаы с предупреждающими сообщениями',
    assetInUnknownCondition: 'Asset in unknown condition | Assets in unknown condition',
    assetInGoodCondition: 'Машины в исправном состоянии',
    dataCoverage: 'Покрытие данных',
    priorityError: 'Критические ошибки | Критические ошибки',
    acknowledged: 'Подтвердить',
    fixed: 'Исправленный',
    context: 'контекст',
    engineSpeed: 'Частота вращения двигателя',
    cooleantLevel: 'уровень охлаждающей жидкости',
    assetHealth: 'Состояние машины',
    fuelType: 'Вид топлива',
    hourMeter: 'Счетчик моточасов',
    risk: 'Риск',
    insights: 'Сведения ',
    active: 'Активно',
    currentShift: 'Текущая смена',
    currentDay: 'текущий день',
    previousShift: 'Предыдущая смена',
    previousDay: 'Предыдущий день',
    last24Hours: 'Последние 24 часа',
    last7Days: 'Последние 7 дней',
    last14Days: 'Последние 14 дней',
    last21Days: 'Последние 21 дней',
    last30Days: 'Последние 30 дней',
    component: 'Подсистема',
    start: 'Время начала',
    count: 'Interne Zählung',
    HealthExplorer: 'Обслуживание',
    summaryByAssetType: 'Обзор по типу машины',
    summary: 'Краткое содержание',
    details: 'Подробности',
    recommendedAction: 'Pекомендуемое действие',
    insightsBySeverity: 'Сведения по степени тяжести',
    assetActivity: 'Активность машин',
    insightsByOccurrence: 'Сведения по случаям',
    insightsByCount: 'Сведения по количеству',
    insightsByDuration: 'Сведения по продолжительности',
    badgeText: 'последние {x} дней',
    faultCount: 'случаев',
    duration: 'Продолжительность',
    variation: 'Изменение',
    since: 'поскольку',
    selectedIssueNotInTimeframe: 'Выбранный интеллект больше недоступен.',
    rawDataForInsight: 'Исходные данные для разведки',
    showMore: 'показать больше',
    showLess: 'показывай меньше',
    area: 'область',
    fmiDescription: 'FMI Description',
    spareParts: 'Запчасть | Запчасти',
    engine: {
      make: 'Производитель',
      unitNumber: 'Номер детали',
      softwareIdentificationFields: 'Количество идентификационных полей ПО',
      softwareIdentification: 'Идентификация ПО',
      ecu: {
        partNumber: 'Артикул блока управления',
        location: 'Местоположение блока управления',
        manufacturerName: 'Название производителя блока управления',
        hardwareVersion: 'Код блока управления',
        constructionDate: 'Дата изготовления  блока управления',
        serialNumber: 'Серийный номер блока управления',
        type: 'Модель блока управления',
      },
    },
    serviceAlerts: {
      nextService: 'Следующая услуга',
      serviceDate: 'Дата обслуживания',
      interval: 'время до обслуживания',
      number: 'число',
      quantity: 'количество',
      service: 'Сервис',
    },
  },
  severityLevels: {
    high: 'Высокий',
    medium: 'Средний',
    low: 'Низкий',
  },
  severityCodes: {
    priority: 'степень срочности',
    red: 'ошибка',
    yellow: 'предупреждение',
    error: 'ошибка',
    warning: 'предупреждение',
    malfunction: 'нарушение',
    protection: 'защита',
    ok: 'Ok',
    nodata: 'нет данных',
  },
  notifications: {
    title: 'уведомления',
    priorityError: 'обнаружена {count} критическая ошибка  | обнаружено {count} критических ошибок',
    engineIssue: 'имеется {count} проблема с двигателем | имеется {count} проблемы с двигателем',
    productionIssue: 'имеется {count} производственная проблема | имеется {count} производственные проблемы',
    bomagError: 'has {count} red Bomag failure code | has {count} red Bomag failure codes',
    overspeedIssue: 'зафиксировано превышение скорости {speed} в {geofence} в течение {duration} секунд между {startTime} & {endTime}',
    endOfList: 'Это все ваши уведомления за последние 7 дней!',
    markAll: 'Отметить все как прочитанное',
    subtitle: {
      unread: 'непрочтённое сообщения',
      all: 'все сообщения',
    },
    tooltip: {
      viewUnread: 'непрочтённое прочитанные сообщения',
      viewAll: 'просмотреть все сообщения',
      markRead: 'oтметить как прочтённое',
      markUnread: 'отметить как непрочтённое',
    },
  },
  dashboardInformationSettings: {
    default: 'Нет логотипа',
    select: 'Выберите логотип',
  },
  errorStatisticsSettings: {
    select: 'Выбрать фильтр',
  },
  deleted: {
    assets: 'deleted asset | deleted assets',
  },
  showUnknowns: 'Отфильтровать неизвестные диапазоны цикла',
  groupBy: 'Группа по',
  unknownOEM: 'неизвестный Производители(OEM)',
  unknownModel: 'неизвестный модель',
  unknownType: 'неизвестный тип',
  noFilter: 'Нет активных фильтров',
  selectedAssets: 'Выбранная машина | Выбранные машины',
  selectedAssetsInfo: 'Выбрано все {x} машин',
  assetNotSelectedInfo: 'пожалуйста, выберите хотя бы машину | пожалуйста, выберите по крайней мере 2 машины',
  assetGroupNotSelectedInfo: 'Пожалуйста, выберите вариант',
  pivotTable: {
    sourceGeofence: 'исходная геозона',
    targetGeofence: 'целевая геозона',
    materialType: 'тип материала',
    noCycles: 'циклы',
    tonnage: 'тоннаж',
    sumDistance: 'расстояние',
    avgDistance: 'среднее расстояние (∅)',
    sumDuration: 'длительность',
    avgDuration: 'длительность (∅)',
    tonnageKilometer: 'тоннаж топлива на километр',
    fuelPerTonnage: 'потребление топлива на одну тонну',
    fuelPerHour: 'потребление топлива за один час',
    energyConsumptionPerTonnage: 'номинальное энергопотребление на тонну',
    energyConsumptionPerHour: 'номинальное энергопотребление в час',
    sourceAsset: 'исходная машина',
    targetAsset: 'целевая машина',
    asset: 'Машина',
    nonTalpaAsset: 'Машина без Talpa',
  },
  advancedSearch: {
    hotkeyExplanations: {
      enter: 'to select',
      arrows: 'to navigate',
      esc: 'to exit search',
    },
  },
  marketplace: {
    productTypes: {
      all: 'All',
      app: 'Apps',
      bundle: 'Bundles',
    },
  },
  defaultApp: 'Приложение по умолчанию',
  validationErrors: {
    required: 'Это поле является обязательным.',
    requiredIf: 'Это поле является обязательным.',
    minLength: 'Text should be longer',
  },
  durationFormatSettings: {
    title: 'десятичный формат',
    label: 'Включить десятичный формат для ключевых показателей эффективности, основанных на времени',
  },
  planning: {
    myCalendars: 'Мои календари',
    createCalendar: 'Создать календарь',
    addSchedule: 'Добавить задачу',
    ownerTypePlaceholder: 'Выбрать тип администратора',
    ownerType: 'Тип администратора',
    self: 'Собственный',
    ownerPlaceholder: 'Выбрать администратора',
    createSchedule: 'Создать задачу',
    editSchedule: 'Изменить задачу',
    timeSettings: 'Настройки времени',
    goals: 'Цели',
    scheduleTypePlaceholder: 'Выбрать тип задачи',
    scheduleType: 'Тип задачи',
    recurringTask: 'Повторяющаяся задача',
    createShiftReport: 'Создать отчет за смену',
    assignedAssets: 'Выбранные машины',
    allAssetsOfOwner: 'Все машины администратора({x})',
    custom: 'Выбрать машины по собственному усмотрению',
    scheduleStartDate: 'Задать начальную дату',
    freqency: 'Частота',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно',
    yearly: 'Ежегодно',
    interval: 'Промежуток',
    daysInWeek: 'Дни недели',
    weekDays: {
      mo: 'Пн',
      tu: 'Вт',
      we: 'Ср',
      th: 'Чт',
      fr: 'Пт',
      sa: 'Сб',
      su: 'Вс',
    },
    addGoal: 'Добавить цель',
    createGoal: 'Создать новую производственную цель',
    editGoal: 'Редактировать производственную цель',
    assignedAssetsGoal: 'Машины, зарегистрированные для данной цели',
    allAssetsOfSchedule: 'Все машины({x})',
    rule: 'Правило | Правила',
    addRule: 'Добавить правило',
    min: 'Мин',
    max: 'Макс',
    range: 'Выборка',
    below: 'Ниже',
    above: 'Выше',
    createRule: 'Создать правило',
    updateRule: 'Отредактировать правило',
    selectAssetDimension: 'Выбор параметров',
    selectRuleType: 'Вабрать тип правила',
    ruleType: 'Тип правила',
    threshold: 'Предел',
    more: 'еще {x}',
    reports: 'Отчёты',
    messages: {
      noCalendars: 'You dont have any calendars',
      noSchedules: 'No schedules in calendar',
      newCalendarCreated: 'Календарь {x} был успешно создан.',
      addNewSchedule: 'Вы хотите добавить задачу в календарь?',
      removeGoal: 'Вы хотите удалить цель из задачи <i>{x}</i>?',
      removeRule: 'Хотите удалить правило <i>{x}</i>?',
    },
    timezoneOptions: {
      schedule: 'План',
      local: 'Местное',
      utc: 'UTC',
    },
    preview: 'просмотр',
    previewMsg: 'Контрольные карты для {x} предыдущих событий расписания',
  },
  viewCountOfInstance: 'Показать ось Y в количестве экземпляров',
  count: 'случаев',
  cycleTable: {
    cycleID: 'ID цикла',
    start: 'Начало',
    end: 'конец',
    sourceAsset: 'исходная машина',
    targetAssetTalpaID: 'целевая машина',
    sourceGeofence: 'исходная геозона',
    sourceAssetTalpaID: 'исходная машина',
    asset: 'Источник',
    targetGeofence: 'целевая геозона',
    materialType: 'тип материала',
  },
  massflow: 'массовый расход',
  none: 'ничего',
  activity: 'действие',
  loadmore: 'показать больше',
  enginesManager: {
    engine: 'Двигатель',
    heading: 'Управление Двигателями Оборудования',
    nthEngineHeading: 'Управление {nth} двигателями',
    serialNumber: {
      label: 'Серийный номер',
      validations: {
        required: 'Серийный номер является обязательным',
      },
    },
    ecu: {
      partNumber: 'Артикул блока управления',
    },
    make: {
      name: 'Производитель',
      create: 'Добавить производителя',
      required: 'Производитель является обязательным',
    },
    model: {
      name: 'Модель',
      create: 'Добавить модель',
      required: 'Модель является обязательной',
    },
    energy: {
      source: 'Источник',
    },
    addEngine: 'добавить двигатель',
    addAnotherEngine: 'Добавить еще один двигатель',
    updateEngine: 'Обновить двигатель',
    saveEngine: 'Сохранить двигатель',
  },
  historicalAssetHealth: {
    title: 'Состояние машины',
    mtbf: 'Период между появлением неисправностей',
    mttr: 'Период устранения неисправности',
    tooltip: {
      sickPeriods: 'Больничный час',
      errors: 'недостаток | недостатки',
      warnings: 'Предупреждение | Предупреждений',
      healthyPeriod: { info: `<strong>Нет неисправностей</strong> означает, что машина работает без упомянутых ниже неисправностей.` },
      sickPeriod: {
        info: `<strong>Есть неисправности</strong> указывает на время работы машины с зарегистрированными неисправностями двигателя в сочетании с кодами FMI 0, 1, 16 или 18.`,
      },
      unknownPeriod: {
        info: `<strong>Нет информации</strong> означает отсутствие данных.`,
      },
      mttbs: 'MTBS (помесячно) = Время работы / Общая продолжительность периодов с наличием неисправности',
      mttrs: 'MTTRS (помесячно) =  Общая продолжительность / Количество периодов с наличием неисправности',
    },
    sickPeriods: {
      tooltip: {
        duration: 'Продолжительность: {duration}',
      },
    },
    status: {
      healthy: 'Нет неисправностей',
      sick: 'Есть неисправности',
      unknown: 'Нет информации',
    },
    sickPeriod: 'Период наличия неисправности',
    errorCode: 'Код ошибки # {errorCode}',
    sickHours: 'Период наличия неисправности',
    operationTime: 'время фактической работы',
  },
  unknownUsergroup: 'Неизвестная группа пользователей',
  shortMinute: 'мин',
  analytics: 'Аналитика',
}
