export default {
  navigation: {
    archon: {
      home: 'Home',
      map: 'Map',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      signals: 'Signale',
      settings: 'Settings',
      reporting: 'Berichte',
      users: 'Users',
      user: 'User',
      createUser: 'Create User',
      assetDimensions: 'AssetDimensions',
      assetDimension: 'AssetDimension',
      assets: 'Assets',
      asset: 'Asset',
      logger: 'Logger',
      loggers: 'Loggers',
      createLogger: 'Create logger',
      geofences: 'Geofences',
      organizations: 'Organizations',
      organization: 'Organization',
      subsidiaries: 'User groups',
      subsidiary: 'User group',
      sites: 'Sites',
      site: 'Site',
      login: 'Login',
      jobs: 'Scheduled Jobs',
      event: 'Events',
      events: 'Events',
      wheels: 'Wheels',
      wheel: 'Wheel',
      tyres: 'Tyres',
      tyre: 'Tyre',
      createTyre: 'Create Tyre',
      createWheel: 'Create Wheel',
      duplicateUser: 'Duplicate User',
      listCalendars: 'Overview',
      calendars: 'calendars',
      calendar: 'calendar',
      createCalendar: 'Create calendar',
      'heavy-equipment': 'Heavy Equipment',
      job: 'job',
      createJob: 'create job',
      assetTypes: 'Asset Types',
      createAssetType: 'Create Asset Type',
      assetUtilizationCategories: 'Utilization Categories',
      createAssetUtilizationCategory: 'Create Utilization Category',
      userPermissionSummary: 'User Permissions',
      ListSchedules: 'Schedules',
      EditSchedule: 'Edit Schedule',
      RescheduleJobs: 'Reschedule Jobs',
      Marketplace: 'Marketplace',
      ListProducts: 'Products',
      EditProduct: 'Product',
      CreateProduct: 'Create Product',
      reports: 'Reports',
      shiftReports: 'Shift Reports',
      acquisitions: 'Acquisitions',
      createAcquisition: 'Acquisitions',
      acquisition: 'Acquisitions',
      OrganizationAcquisitions: 'Organization Acquisitions',
    },
    cockpit: {
      home: 'Home',
      map: 'Map',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      signals: 'Signals',
      settings: 'Settings',
      reporting: 'Reporting',
      login: 'Login',
      asset: 'Asset Profile',
      organizations: 'My Organizations',
      subsidiary: 'Organizations',
      assetList: 'Assets',
      feed: 'Feed',
      planning: 'Planning',
      privacyPolicy: 'Privacy Policy',
      AssetOverview: 'Asset Profile',
      AssetLog: 'Asset Profile',
      maintenance: 'Maintenance',
      marketplace: 'Marketplace',
      HealthExplorer: 'Maintenance',
      serviceDealer: 'Service',
      ServiceDealerHealthExplorer: 'Service',
      Planning: 'Planning',
      extension: 'Extensions',
      ListExtensions: 'Extensions',
      EditExtension: 'Edit Extension',
      CreateExtension: 'Create Extension',
      AddAssets: 'Add Assets',
      AddAssetDimension: 'Add Asset Dimension',
      historicalAssetHealth: 'Historical Asset Health',
      HistoricalAssetHealthList: 'Historical Asset Health',
    },
    subRoutes: {
      AssetHealthOverview: 'Overview',
      AssetHealthInsight: 'Insights',
      AssetHealthStatistics: 'Statistics',
      ServiceDealerAssetHealthOverview: 'Overview',
      ServiceDealerAssetHealthInsight: 'Insights',
      ServiceDealerAssetHealthStatistics: 'Statistics',
      HistoricalAssetHealthOverview: 'Overview',
    },
  },
  actions: {
    create: 'create',
    share: 'share',
    ok: 'ok',
    apply: 'apply',
    cancel: 'cancel',
    archive: 'archive',
    clearAll: 'clear all',
    login: 'log in',
    logout: 'log out',
    goTo: 'go to',
    hideEmpty: 'hide empty',
    showDetails: 'show details',
    showActivityColors: 'show activity colors',
    backToOverview: 'back to overview',
    choose: 'choose',
    add: 'add',
    delete: 'delete',
    pressEnterToSelect: 'Press enter to select',
    check_it_out: 'check it out',
    learn_more: 'how to',
    duplicate: 'duplicate',
    unsubscribe: 'unsubscribe',
    scrollToTop: 'scroll to top',
    readMore: 'read more ...',
    contactUs: 'Contact us',
    gotIt: 'Got it',
    read: 'Read it here',
    search: 'Search...',
    selectAll: 'Select All',
    deselectAll: 'Unselect All',
    saveFilters: 'Save',
    moreDetails: 'More details',
    goToInsights: 'Go to Insight | Go to Insights',
    goToOverviews: 'Go to Overview',
    goToSignals: 'Go to Signals',
    goToAssetHealthOverview: 'Go to Asset Health Overview',
    showMore: 'Show more',
    showLess: 'Show less',
    showSettings: 'Show Settings',
    hideSettings: 'Hide Settings',
    updateAcquisition: 'Update Acquisition',
    assignProduct: 'Assign Product',
    update: 'Update',
    close: 'Close',
    copyInformation: 'Copy Information',
    seeRelatedSignals: 'See related signals',
  },
  placeholders: {
    searchAssetDimensions: 'search available Dimensions',
    selectOption: 'Select option',
  },
  messages: {
    unauthorized: 'Sorry, you have no access rights to this page',
    pageNotFound: 'Sorry, the page you are requesting does not exist',
    noData: 'No data to display. Please contact your Talpa contact person for more information.',
    notAvailable: 'Not available',
    noDataForSelectedTime: 'No data for the selected time period.',
    noDataWithType: 'No {type} yet.',
    noNotifications: 'No new notifications.',
    newDashboard: 'New Dashboard',
    errorLoadingOverlay: 'There was an error loading the overlay.',
    loggingIn: 'signing in',
    emptyDashboard: 'This Dashboard has no widgets yet.',
    emptyDashboardAdd: 'Click on the Tools button below to add some.',
    noSignalsSelected: 'So signals selected yet. Click on the button below to add some.',
    comingSoon: 'Coming soon!',
    widgetNotCompatible: 'Widget not compatible with dashboard',
    errorLoadingWidgetType: "Error while loading widget of type '{type}'.",
    errorLoadingWidget: 'Error while loading the widget.',
    selectOneDay: 'Please select only one day',
    searchNoResults: 'No results for {query}',
    notUnique: 'The value your provided is already taken',
    required: 'This field is required',
    minValue: 'Field must have at least {value} digits',
    maxValue: 'Field cannot have more than {value} digits',
    noDimensionsRequired: 'No dimensions required for this type of widget',
    entityCreatedSuccessfully: '{entity} created successfully.',
    entityDeletedSuccessfully: '{entity} deleted successfully.',
    entityUpdatedSuccessfully: '{entity} updated successfully.',
    entityAddedSuccessfully: '{entity} added successfully.',
    noAdditionalEntitiesAvailable: 'No additional {entity} available.',
    sessionEnded: 'Your session expired. Please login in again.',
    maxDimensionsAllowed: 'Max. number of allowed dimensions',
    incompatibleDashboardType: 'This widget is only available for Single View dashboards',
    hasTooManyDimensions: 'The maximum number of dimensions has been exceeded for creation of this widget',
    hasTooFewDimensions: 'To create this widget you need to select more dimensions',
    noDimensionsAllowed: 'To create this widget please deselect the chosen asset dimensions',
    noSubsidiaries: 'No user groups yet',
    addSubsidiary: 'Add user group',
    noAdditionalData: 'no additional data',
    tyreNotAvailableTitle: 'Monitor your tyres',
    tyreNotAvailableMsg: 'This feature is not available to you yet. Contact us for more information.',
    new: 'NEW',
    notSupported: 'This Widget is not supported anymore!',
    assetSelector: 'Select at least one Asset',
    noSignalData: 'No data for this signal',
    noDataAvailable: 'No data available.',
    noDataShort: 'No data.',
    clickToInteract: 'Click to interact with chart.',
    minText: 'Field must have at least {value} letters.',
    addProduct: 'Assign a product',
    noAcquisition: 'No products assigned yet',
    productsTierNotAvailable:
      'Either the product doesnt have a tier level configured, Or the organization selected have already acquired the highest Tier of the selected product',
    noDimensionData: 'No {dimension} for the selected time period and/or selected assets',
    dataOutOfRange: '>3 months ago',
    confirmEngineSave: 'You are making changes to Engine of this Asset are you sure you want to save these details?',
    confirmCancel: 'Your changes will be discarded, are you sure you want to Cancel?',
    noSpareParts: 'No spare parts are available!',
    maintenanceCopySuccess: 'Service details successfully copied!',
    noSickPeriod: 'No fault data available for the selected month.',
    cookieBanner:
      'We use analytics to gain insights into how our product is used and to enhance your experience. You can choose to opt out at any time by selecting Decline in the analytics section of your settings.',
  },
  all: 'all',
  backToTop: 'Back to Top',
  noData: 'No data',
  assetDimension: 'AssetDimension | AssetDimensions',
  alias: 'alias | aliases',
  app: 'app | apps',
  applicationHasErrors: 'There are {count} errors.',
  asset: 'asset | assets',
  allAssets: 'all assets',
  assetType: 'Asset Type | Asset Types',
  assetModel: 'asset Model | asset Model',
  bundle: 'Bundle | Bundles',
  loggerModel: 'Logger Model',
  code: 'Code | Codes',
  condition: 'condition | conditions',
  date: 'date | dates',
  designation: 'designation | designations',
  category: 'category | categories',
  utilizationCategory: 'Utilization Category | Utilization Categories',
  dimension: 'dimension | dimensions',
  column: 'Column | Columns',
  row: 'Row | Rows',
  duration: 'duration | durations',
  email: 'Email | Emails',
  username: 'Username | Usernames',
  role: 'Role | Roles',
  end: 'end | ends',
  geofence: 'Last Position | Last Positions',
  icon: 'icon | icons',
  language: 'language | languages',
  lastUpdate: 'Last Analysis | Last Analysis',
  location: 'Location | Locations',
  model: 'Model | Models',
  member: 'Member | Members',
  name: 'Name | Names',
  now: 'now',
  oem: 'OEM | OEMs',
  organization: 'organization | organizations',
  acquisition: 'Acquisition | Acquisitions',
  overview: 'Overview | Overviews',
  picture: 'picture | pictures',
  profile: 'profile | profiles',
  setting: 'setting | settings',
  severity: 'severity | severities',
  site: 'Site | Sites',
  unknownSite: 'Unknown site | Unknown sites',
  start: 'start | starts',
  status: 'status | status',
  schedule: 'schedule | schedules',
  totalMachineHours: 'Engine Hours',
  targetValue: 'target value | target values',
  sum: 'sum | sums',
  talpaID: 'TalpaID | TalpaIDs',
  technicalID: 'TechnicalID',
  time: 'time | times',
  title: 'title | titles',
  total: 'Total | Totals',
  type: 'Type | Types',
  unit: 'unit | units',
  user: 'user | users',
  owner: 'owner | owners',
  subsidiary: 'User Group | user groups',
  widget: 'widget | widgets',
  myAccount: 'my account | my accounts',
  myOrganizations: 'my organization | my organizations',
  warning: 'Warning | Warnings',
  error: 'Error | Errors',
  overall: 'overall',
  job: 'job | jobs',
  event: 'event | events',
  calendar: 'calendar | calendars',
  utilizationInShift: 'Utilization in Shift',
  machineHealth: 'Machine health',
  health: 'Health',
  Health: 'Health',
  healthStatus: 'Health Status',
  tyre: 'Tyre | Tyres',
  serialNumber: 'Serial number | Serial numbers',
  wheel: 'wheel | wheels',
  logger: 'Logger',
  loggers: 'Loggers',
  errorLog: 'Error log | Error logs',
  lastLocation: 'Last Location',
  integratedSince: 'Integrated Since',
  manufacturer: 'Manufacturer',
  currentLocation: 'Current Location',
  product: 'Product | Products',
  tier: 'Tier | Tier',
  machineHealthLabels: {
    noFaults: 'No faults detected',
    noWarningsErrors: "No warnings or errors were detected during last machine's status update",
    moreCategories: '{x} more',
  },
  assetHealthStatus: {
    Green: 'OK',
    Yellow: 'Warning',
    Red: 'Error',
    Unknown: 'Not Available',
  },
  membershipRoles: {
    user: 'user',
    admin: 'administrator',
  },
  itIsYou: "It's you!",
  warnings: 'Warnings',
  languages: {
    de: 'Deutsch',
    en: 'English',
    ru: 'Русский',
  },
  uiSettings: {
    units: {
      METRIC: 'metric units',
      IMPERIAL: 'imperial units (US)',
    },
    time: {
      AM_PM_FORMAT: '12h-format',
      TWENTYFOUR_FORMAT: '24h-format',
    },
    defaultApp: {
      MAINTENANCE_APP: 'Maintenance App',
      SD_APP: 'Service App',
    },
    analytics: {
      OPT_IN: 'Accept Tracking',
      OPT_OUT: 'Decline Tracking',
    },
  },
  types: {
    user: 'User | Users',
    dashboard: 'Dashboard | Dashboards',
    signal: 'Signal | Signals',
    report: 'Report | Reports',
    kpiCards: 'KPI Card | KPI Cards',
  },
  dashboard: {
    time: {
      calendarWeek: 'CW',
      today: 'Today',
    },
    errors: {
      titleExists: 'Dashboard name is already taken',
      createError: 'To create a fleet dashboard, you need to select at least two machines.',
    },
    types: {
      placeholder: 'Type',
      placeholderFilter: 'Filter',
      fleet: 'Fleet View',
      single_asset: 'Single View',
      template_single_asset: 'Default Template',
      template_fleet: 'Template for Single View',
    },
    filters: {
      NONE: 'None',
      MANUAL: 'Custom',
      ASSET_TYPE: 'Asset Type',
      MODEL: 'Model',
      OEM: 'OEM',
      SITE: 'Site',
      USER_GROUP: 'User Group',
    },
    attributes: {
      date_range: 'date range',
      writing_access: 'Writing access',
      sharedWith: 'Shared with',
      allUsers: 'All Users',
      shareDashboardWith: 'Search User ...',
      noUsersFound: 'No users found',
    },
    tools: {
      tool: 'tool | tools',
      addWidget: 'Add a widget',
      editWidget: 'Edit widgets',
      copyDashboard: 'Duplicate this dashboard',
      shareDashboard: 'Share this dashboard',
    },
    shift: 'Shift | Shifts',
    shiftsView: 'Shift View',
    shiftSelect: 'Please select a Shift System',
    shiftsNotAvailable: 'No shift system is available for the selected machines',
    shiftSystem: 'Shift System',
    shiftName: 'Shift Name',
    shiftTimes: 'Shift Times',
    shiftStart: 'Shift Start',
    shiftEnd: 'Shift End',
    dashboardSettings: 'dashboard settings',
    owner: 'owner',
    dashboardSharedMessage: 'you shared this dashboard',
    all: 'All',
    sharedByYou: 'Shared by you',
    sharedByOther: 'Shared by others',
  },
  assetSignalOverlay: {
    card_name: 'Name of the card',
  },
  map: {
    assetSelector: {
      placeholder: 'all assets',
    },
    legend: 'Legend',
    average: 'Avg',
    max: 'Max',
    mapSettings: 'Map settings',
    geofences: 'Geofences',
    heatmap: 'Heatmap',
    info: 'Please select a dimension in the heatmap section first.',
    satelliteStyle: 'Satellite view',
    messages: {
      zoomInForHeatMap: 'Zoom in to view heat map',
      generatingHeatMap: 'Generating heat map',
      generatingGeofences: 'Generating geofences',
      allMachinesNoLocation: 'Location data is not available for the selected machines',
      someMachinesNoLocation: 'Location data is not available for some selected machines',
      selectedMachinesNoHeatMap: 'Heat map data is not available for the selected machines',
    },
  },
  titles: {
    addDashboard: 'Create new Dashboard',
    editDashboard: 'Edit Dashboard',
    shareDashboard: 'Share Dashboard',
    addWidget: 'Add Widget',
    editWidget: 'Edit Widget',
    pleaseConfirm: 'Please confirm',
    yourOrganizations: 'Your Organizations',
    availableDimensions: 'Available Asset Dimensions',
    configureDimensions: 'Configure Asset Dimensions',
    widgetType: 'Widget type',
    widgetOptions: 'Widget options',
    widgetPreview: 'Widget preview',
    showPreview: 'Show preview',
    mostRecent: 'most recent',
    mostUsed: 'most used',
    sessionEnded: 'session expired',
    createAcquisition: 'Create Acquisition',
    addCalendar: 'Create new Calendar',
    editCalendar: 'Edit Calendar',
    addSchedule: 'Create new Schedule',
    editSchedule: 'Edit Schedule',
  },
  createdAt: 'created',
  updatedAt: 'last edit',
  runAt: 'run at',
  dashboardType: 'Dashboard Type',
  noOfAssets: 'Number of Assets',
  noOfSharedUsers: 'Number of Shared Users',
  wizard: {
    steps: {
      dimensions: 'dimensions',
      widget: 'visualizations',
      general: 'general',
    },
    dimensions: {
      addDimension: 'Add Dimension',
      noneAvailable: 'None available.',
    },
  },
  widgetTypes: {
    TABLE: 'Table',
    MULTI_CHART: 'Multi Graph',
    PIE_CHART: 'Pie Chart',
    MAP: 'Map',
    SANKEY_CHART: 'Sankey Chart',
    TIMELINE_CHART: 'Timeline Chart',
    ASSET_INFORMATION: 'Asset Information',
    ERROR_LOG: 'Error Log',
    MULTI_DIMENSION: 'Multi Dimension',
    DRILLING_TABLE: 'Drilling Table',
    CYCLE_TABLE: 'Cycle Table',
    CARD: 'Card',
    HISTOGRAM: 'Histogram',
    ERROR_STATISTICS: 'Fault Statistics',
    DASHBOARD_INFORMATION: 'Dashboard Information',
    PIVOT_TABLE: 'Pivot Table',
  },
  graphTypes: {
    placeholder: 'graph type',
    LINE: 'line',
    COLUMN: 'column',
  },
  selects: {
    asset: 'select asset | select assets',
    general: 'Select option',
    dimension: 'select a dimension',
    activities: 'select activities',
    type: 'Select Type',
    model: 'Select Model',
    oem: 'Select OEM',
    site: 'Select Site',
    organization: 'Select Organization',
    product: 'Select Product',
    productTier: 'Select Product Tier Level',
    acquisition: 'Select Acquisition type',
    usergroup: 'Select User Group',
  },
  assetTypes: {
    roadheader: 'Roadheader',
    scaler: 'Scaler',
    loadhauldump: 'Load Haul Dump',
    surfacedumper: 'Surface Dumper',
    tractor: 'Tractor',
    fueltanktruck: 'Fuel Tank Truck',
    undergrounddumper: 'Underground Dumper',
    testingtype: 'TestingType',
    shuttlebus: 'Shuttle Bus',
    locomotive: 'Locomotive',
    highwaytruck: 'Highway Truck',
    drillrig: 'Drill Rig',
    loader: 'Loader',
    roofbolter: 'Roof Bolter',
    plant: 'Plant',
    largeholeauger: 'Large Hole Auger',
    dozer: 'Dozer',
    continuousminer: 'Continuous Miner',
    bolterminer: 'Bolter Miner',
    excavator: 'Excavator',
    wheelbasedexcavator: 'Wheel Based Excavator',
    ropeexcavator: 'Rope Excavator',
    surfacedrillrig: 'Surface Drill Rig',
    streetsweeper: 'Street Sweeper',
    roadroller: 'Road Roller',
    conveyor: 'Conveyor',
    crusher: 'Crusher',
    cleanerloader: 'Cleaner Loader',
    compressor: 'Compressor',
    concretepump: 'Concrete Pump',
    towtractor: 'Tow Tractor',
    forklift: 'Fork Lift',
    loadingtransporter: 'Loading Transporter',
    trackconstructiontrain: 'Track Construction Train',
    highpressurepump: 'High Pressure Pump',
    utilitytruck: 'Utility Truck',
    wastedumptruck: 'Waste Dump Truck',
    electrictowingtractor: 'Electric Towing Tractor',
    straddlecarrier: 'Straddle Carrier',
    mobilescreen: 'Mobile Screen',
    tegelträger: 'Crucible handler',
    hybridantrieb: 'Hybrid drive',
    telescopichandler: 'Telescopic Handler',
    recycler: 'Recycler',
    compactor: 'Compactor',
    coldmilling: 'Cold Milling',
    paver: 'Paver',
    materialhandler: 'Material Handler',
    wastehandler: 'Waste Handler',
  },
  reporting: {
    report: {
      title: 'FLEET REPORT',
      userName: 'Name',
      organization: 'Organization',
      site: 'Mine',
      unit: 'Unit',
      totalAssets: 'Total Assets',
      downloadDate: 'Download Date',
      timeFormat: 'Time format',
      datePicker: 'Date Picker',
      selectedTime: 'Report selected time',
      loadingText: 'Please wait, your report is being prepared...',
      processing: 'Processing',
    },
    error: {
      downloadError: 'Download failed: Please try to download the report again later.',
    },
    table: {
      assetDimensions: 'KPI',
      deviation: 'Deviation',
    },
    csv: {
      days: 'Days',
      startDate: 'Start date time',
      endDate: 'End date time',
    },
    sections: {
      utilization: 'Utilization',
      production: 'Production',
      fuel_consumption: 'Fuel consumption',
      errors: 'Errors',
      summary: 'Summary',
    },
    stepper: {
      next: 'Next',
      back: 'Back',
      finish: 'Download',
      stepOne: {
        title: 'Date',
        subtitle: 'Report date range',
        types: {
          daily: 'Day',
          weekly: 'Week',
          monthly: 'Month',
          yearly: 'Year',
          calendarWeek: 'CW',
        },
        disabled: 'disabled',
      },
      stepTwo: {
        title: 'Report sections',
        subtitle: '',
      },
      stepThree: {
        title: 'Download',
        subtitle: 'Download type  ',
        selectedRange: 'Selected range',
        selectedSections: 'Selected sections',
        downloadReportType: 'Download report type',
      },
    },
  },
  confirm: {
    delete: {
      Widget: 'Do you really want to delete this widget?',
      Dashboard: 'Do you really want to delete this dashboard?',
      Dashboard_share: 'Do you really want to unsubscribe this dashboard?',
      AssetDimension: "Do you really want to delete the AssetDimension '{name}'?",
      Asset: "Do you really want to delete the asset '{name}'?",
      Site: "Do you really want to delete the site '{name}'?",
      Organization: "Do you really want to delete the organization '{name}'?",
      User: "Do you really want to delete the user '{name}'?",
      Subsidiary: 'Do you really want to delete the user group?',
      Membership: 'Do you really want to delete this membership?',
      Schedule: 'Do you really want to delete this entry?',
      ShiftReport: 'Do you really want to delete this entry?',
      Job: 'Do you really want to delete this job?',
      signal: {
        card: 'Do you really want to delete this card?',
      },
      wheelPosition: 'Do you really want to unmount or delete this Asset wheel position?',
      tyreUnmount: 'Do you really want to unmount this tyre?',
      ConfidenitalClient: 'Do you really want to delete this confidential client?',
      Acquisition: 'Do you really want to delete the Acquisition',
      Product: "Do you really want to delete the product '{name}'.",
      AssetWithInSubsidiary: "You are trying to delete an Asset that is part of '{name}'. Are you sure you want to delete it?",
      Extension: "Do you want to delete the Extension '{name}' ?",
      ExtensionAsset: "Do you want to remove the selected Asset '{name}' ?",
      ADDefinition: "Do you want to remove the selected Asset Dimension '{name}' ?",
    },
    edit: {
      subsidiary: 'Edit User Group Name',
    },
  },
  times: {
    timeAgo: '{count} {type} ago',
    invalid: 'invalid timestamp',
    millisecond: 'millisecond | milliseconds',
    second: 'second | seconds',
    minute: 'minute | minutes',
    hour: 'hour | hours',
    day: 'day | days',
    week: 'week | weeks',
    month: 'month | months',
    year: 'year | years',
    timesAgo: {
      millisecond: 'millisecond | milliseconds',
      second: 'second | seconds',
      minute: 'minute | minutes',
      hour: 'hour | hours',
      day: 'day | days',
      week: 'week | weeks',
      month: 'month | months',
      year: 'year | years',
    },
  },
  signals: {
    headers: {
      search: 'available signals',
      controls: 'selected signals',
      timelines: 'time selection',
      currentSignalValue: 'Current signal values',
    },
    search: {
      placeholder: 'type here to filter',
    },
    aggregation: {
      label: 'aggregation',
      placeholder: 'select aggregation',
      enums: {
        first: 'first value',
        min: 'minimum',
        max: 'maximum',
        avg: 'average',
        count: 'count',
        sum: 'sum',
        last: 'last value',
      },
    },
    tooltip: {
      name: 'Name',
      defaultName: 'Default Name',
      type: 'Type',
      unknown: 'unknown',
      unit: 'Unit',
      firstDataReceived: 'First Data Received',
      lastDataReceived: 'Last Data Received',
    },
  },
  shifts: {
    selectPlaceholder: 'select shift plan',
    errors: {
      interval_error: 'The interval between shift start and end should be at least {minutes} minutes',
    },
  },
  timezone: {
    timezone: 'Time zone',
    browser: {
      title: 'Browser time zone',
      description: 'The time zone that your system currently is working in',
    },
    asset: {
      title: 'Asset time zone',
      description: 'The time zone that each asset individually are located in',
    },
    UTC: {
      title: 'UTC time zone',
      description: 'The time in coordinated universal time',
    },
    selectTimezonePlaceholder: 'Select a timezone',
    browserTime: 'Browser time',
  },
  activityTypes: {
    WORK: 'working',
    IDLE: 'idling',
    UNLOADING: 'dumping',
    TRAVELING: 'traveling',
    TRANSITIONAL_DELAY: 'transitional delay',
    STANDING: 'standing',
    SPOTTING: 'spotting',
    QUEUE_BEFORE_LOAD: 'queuing before loading',
    QUEUE_BEFORE_DUMP: 'queuing before dumping',
    MOVING: 'moving',
    MANEUVERING: 'maneuvering',
    MACHINE_UNLOADING: 'dumping',
    HYDRAULIC_IDLE: 'hydraulic idling',
    DIESEL_IDLE: 'diesel idling',
    ELECTRIC_IDLE: 'electric idling',
    MACHINE_OFF: 'asset off',
    MACHINE_LOADING: 'loading',
    LOADING: 'loading',
    IDLE_UNLOADED: 'idling unloaded',
    IDLE_LOADED: 'idling loaded',
    HOLE_SETUP: 'hole setup',
    DRIVING_UNLOADED: 'driving unloaded',
    DRIVING_LOADED: 'driving loaded',
    DRILLING: 'drilling',
    ANCHORING: 'anchoring',
    PUSH: 'pushing',
    RETURN: 'returning',
    WITHDRAWING_BOOM: 'withdrawing boom',
    SCALING: 'scaling',
    ELECTRICAL_SYSTEM_ON: 'electrical system on',
    REPOSITIONING: 'repositioning',
    SUPPORT_LEG_WARNING: 'support leg warning',
    AUXILIARY_WORK: 'auxiliary work',
    PREPARATION_WORK: 'preperation work',
    WAITING: 'Waiting to Load',
    NO_DATA: 'No Data',
    ROCK_CRUSHING: 'Rock crushing',
    ROD_CHANGE: 'Rod Change',
    SWEEPING: 'Sweeping',
    ROD_REMOVAL: 'rod removal',
  },
  activityTable: {
    asset: 'Asset',
    start: 'Start',
    end: 'End',
    type: 'Type',
    duration: 'Duration (min)',
    tableIcon: 'Table view',
  },
  sankeyChartTable: {
    title: 'Sankey table',
    sourceGeofence: 'Source',
    targetGeofence: 'Target',
    materialType: 'Material type',
    tonnage: 'Tonnage',
    noCycles: 'Cycles',
    sumDistance: 'Distance',
    avgDistance: 'Distance (∅)',
    sumDuration: 'Duration',
    avgDuration: 'Duration (∅)',
    tonnageKilometer: 'Tonnage per Kilometer',
    fuelPerTonnage: 'Fuel consumption per ton',
    fuelPerHour: 'Fuel consumption per hour',
    energyConsumptionPerTonnage: 'Net Energy Consumption per ton',
    energyConsumptionPerHour: 'Net energy consumption per hour',
    sourceAsset: 'Source asset',
    targetAsset: 'Target asset',
    asset: 'Asset',
  },
  errorTable: {
    severity: {
      yellow: 'yellow',
      red: 'red',
    },
    recommendedAction: 'Recommended Action',
    lastDay: 'Last active 24 hours',
    lastHour: 'Last active 1 hour',
    active: 'End of last analysis',
    lastWeek: 'Last 7 days',
  },
  timeScopes: {
    lastHour: 'Last hour',
    lastSixHours: 'Last 6h',
    lastDay: 'Last 24h',
    lastWeek: 'Last week',
  },
  drillingTable: {
    cycle: 'CycleID',
    holeID: 'HoleID',
    asset: 'Source',
    start: 'Start',
    drilledDepth: 'Drilled depth',
    drilledLength: 'Drilled length',
    location: 'Location',
    drilledDuration: 'Drill duration',
  },
  kpiCard: {
    name: 'Name',
    value: 'Wert',
    unit: 'Unit',
    signalName: 'Signal',
    lastSeen: 'Updated at',
  },
  multiDimension: {
    time: 'Evolution over time',
    comparison: 'Machine comparison',
  },
  multiChart: {
    warning: 'found 4 dimensions, but MultiChart just supports 3',
  },
  sankeyChart: {
    cycle: 'cycle | cycles',
  },
  histogramWidgetSettings: {
    histogram: 'Histogram',
    class: 'Class',
    thresholds: 'Thresholds',
    default_setting_by_the_system: 'Default setting by the system',
    set_bin_sizes: 'Set a fixed bin size',
    choose_number_of_bars: 'Choose number of bars',
    type_the_value: 'Type the value',
    max_value: 'Max value',
    min_value: 'Min value',
    error: {
      negativeError: 'Threshold difference must be greater than zero',
      binSizeError: '(Threshold difference / binsize) must be a whole number',
      noOfBinError: 'Number of bins must be 3 or more',
      naNError: 'Invalid input. Please enter numbers only.',
      binNotInteger: 'Invalid input. Please enter whole numbers only',
      invalidInput: 'Invalid input.',
    },
  },
  nonAdminUsersCanSeeOtherUsers: 'Non-admin users can see other users (username and email).',
  dashboardSharingForAllUsers: 'Users can share their dashboards',
  errorStatistics: {
    allMachineMessages: 'all machine faults',
    red: 'errors',
    yellow: 'warnings',
    faults: 'errors',
    warnings: 'warnings',
    errorCount: 'Fault Count',
    errorDuration: 'Fault Duration',
    evolutionOverTime: 'Evolution over time',
    machineComparison: 'Machine comparison',
    categoryComparison: 'Category comparison',
    severity: 'Severity',
  },
  userGuide: {
    title: 'meet our new user management',
    content: 'we are introducing our new user management feature for our system',
  },
  releaseNotes: {
    productName: 'Release notes',
    version: 'version 14.0',
    title: 'Release notes 14.0 is available!',
    content: 'We are introducing the new version of GHH inSiTE',
    learn_more: 'learn more',
  },
  shiftPopup: {
    title: 'setup a new shift',
    content: 'To set up a shift plan for your selected assets, please get in touch with your talpasolutions contact person',
    learn_more: 'get help',
  },
  feed: {
    profile: {
      morning: 'Good morning',
      afternoon: 'Good afternoon',
      evening: 'Good evening',
      info: 'Here is what is going on in your operations',
    },
    dashboard: {
      title: 'my dashboards',
      no_dashboards: 'no dashboard is available',
      create: 'create one',
      toolTip: 'total dashboards',
      popover: {
        title: 'No dashboards available yet',
        info: 'Contact your admin in order to get access to dashboards.',
      },
    },
    asset: {
      title: 'my assets',
      noAssets: 'no asset is accessible for you',
      toolTip: 'total assets',
      search: {
        placeholder: 'search assets',
        noAssets: 'no asset found for your search',
      },
      filter: {
        options: {
          all: 'show all',
          warnings: 'assets with warning',
          errors: 'assets with error',
        },
      },
      popover: {
        title: 'could not find your asset',
        info: 'contact your admin in order to get access to your assets',
      },
    },
    category: {
      all: 'all',
      production: 'production',
      maintenance: 'maintenance',
      safety: 'safety',
      hardware: 'hardware',
      information: 'Information',
    },
    subsidiaries: 'user groups',
    filter: {
      title: 'filter',
      clear: 'clear',
      options: {
        anytime: 'any time',
        lastday: 'last day',
        lastweek: 'last week',
        lastmonth: 'last month',
        lastyear: 'last year',
      },
      label: {
        anytime: 'any time',
        lastday: 'last day',
        lastweek: 'last week',
        lastmonth: 'last month',
        lastyear: 'last year',
      },
    },
    remove: 'remove',
    delete: 'did you delete the feed by mistake?',
    undo: 'undo',
    getHelp: 'get help',
    card: {
      name: 'card',
      search: 'search...',
      cards: 'cards',
      noData: 'no dimension is accessible for you',
      title: 'Your performance',
      warning: 'you can only have 5 cards at the same time, for adding new one, remove some cards',
      addCard: 'Add card',
      addCardHere: 'Click here to add a new card',
      options: {
        lastDay: 'Last 24 hours',
        lastWeek: 'Last 7 days',
        lastMonth: 'Last 30 days',
      },
      popover: {
        title: 'could not find any assetDimension',
        info: 'contact your admin in order to get access to assetDimension',
      },
    },
    feedEndMain: 'go up again',
    feedEndInfo: 'there is no other feed',
    stayTunedInfo:
      'This is just the beginning! Excited what comes next? Stay tuned to discover more operational insights in this area soon.',
    listItemActions: {
      remove: 'remove',
    },
    insightsAvailable: 'insights are available',
    healthStatus: 'Health Status',
  },
  feedItems: {
    SHIFT_REPORT: 'shift report | shift reports',
    TYRES_HEALTH_REPORT: `Your Fleet's Tyres Summary`,
  },
  MachineAssignmentEvents: {
    titles: {
      banner: 'You have access to a new machine',
      bannerNoData: 'Please contact a Talpa admin for more information',
      in: 'in',
      of: 'of',
    },
  },
  PerformanceGoalEvents: {
    goalAchieved: 'Your Performance Goal <i>{x}</i> was Achieved',
    goalNotAchieved: 'Your Performance Goal <i>{x}</i> was not Achieved',
    unit: 'Unit',
    assetDimension: 'KPI Name',
    achieved: 'Achieved',
    targetValue: 'Target Value',
    change: 'Change (%)',
  },
  shiftReportEvent: {
    titles: {
      banner: 'This Shift Insight is based on the last analysis performed on {date} at {time}',
      bannerNoData: 'Could not fetch data due to missing analysis data. Please contact a Talpa admin for more information',
      insights: '{shiftName} Insights - ',
      production: 'Production',
      drilling: 'Drilling',
      auxiliary: 'Auxiliary',
    },
    tableHeader: {
      productionAssets: 'Production Assets',
      drillingAssets: 'Drilling Assets',
      auxiliaryAssets: 'Auxiliary Assets',
    },
    shiftGeneralInformation: {
      date: 'Date',
      reportCreationTime: 'Report Creation Time',
      shiftTimePeriod: 'Shift Duration',
      lastCompleteData: 'Last Complete Data',
      noActiveFailure: 'No Active Failures',
      fuelUsed: 'Fuel Used',
      dataOutOfRange: 'Last known data >7 days ago',
      endOfShift: 'End of shift',
    },
    noData: 'Data not available yet',
  },
  tyresHealthReportEvent: {
    pressureTableHeader: 'The following machine(s) have had incorrect tyre pressure during the last shift',
    temperatureTableHeader: 'The following machine(s) have had overheating tyres during the last shift',
    bottomLink: {
      text1: 'Check current information in',
      link: 'Asset List',
      text2: '',
    },
  },
  plan: {
    showAs: 'Show as',
  },
  tyres: {
    name: 'name',
    position: 'position',
    airPressure: 'air pressure',
    temperature: 'temperature',
    tyreHealth: 'tyre health',
    maintenance: 'maintenance',
    axelPosition: {
      11: 'Front-left',
      12: 'Front-right',
      21: 'Rear-Outer left',
      22: 'Rear-Inner left',
      23: 'Rear-Inner right',
      24: 'Rear-Outer right',
    },
    toolTip: {
      highTemperature: 'Cool down this tyre',
      highPressure: 'Pressure too high',
      lowPressure: 'Refill this tyre',
      noSignal: 'Signal not available',
    },
  },
  privacyPolicy: {
    bannerMessage: 'We have updated our Privacy Policy',
    menu: 'Privacy Policy',
    accept: 'Read and Accept',
  },
  table: {
    sorting: {
      A_TO_Z: {
        asc: 'A to Z',
        desc: 'Z to A',
      },
      NEW_TO_OLD: {
        asc: 'new to old',
        desc: 'old to new',
      },
      GOOD_TO_CRITICAL: {
        asc: 'Good to Critical',
        desc: 'Critical to Good',
      },
      OK_TO_ERROR: {
        asc: 'OK to Error',
        desc: 'Error to OK',
      },
      LOW_TO_HIGH: {
        asc: 'Low to High',
        desc: 'High to Low',
      },
      FRONT_TO_BACK: {
        asc: 'Front to Back',
        desc: 'Back to Front',
      },
    },
    filter: {
      successMessage: 'Your preferences are saved. The saved filters and sorting will be pre-applied on this table. ',
      deleteInfoMessage: 'Are you sure you want to delete your saved filter and sorting preferences of this table?',
      deleteSuccessMessage: 'Your saved preferences have been deleted! ',
      tooltip: {
        save: 'Save Filters',
        reset: 'Clear Filters ',
        delete: 'Delete saved Filters',
        close: 'Close Filter Tray',
        copy: 'Copy results to clipboard',
      },
    },
    copy: {
      successMessage: '{count} rows copied to clipboard',
      errorMessage: 'Error copying rows to clipboard',
    },
  },
  reset: 'Reset',
  molecules: {
    assetEngine: {
      noEngineData: 'No engine data available',
      aggregators: 'MIN/AVG/MAX',
      filling_load_levels: 'Filling/Load Levels',
      temperatures: 'Temperatures',
      pressures: 'Pressures',
      counters: 'Counters',
      signals: {
        engine__1_engine_coolant_level_1: 'Engine Coolant',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_volume: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_level: 'AdBlue',
        engine__1_aftertreatment_1_diesel_particulate_filter_soot_load_percent: 'Soot Load',
        engine__1_aftertreatment_1_diesel_particulate_filter_ash_load_percent: 'Ash Load',
        engine__1_engine_oil_life_remaining: 'Remaining Oil Life',
        engine__1_engine_oil_level: 'Engine Oil',
        engine__1_engine_intake_manifold_1_temperature: 'Intake Manifold',
        engine__1_engine_exhaust_temperature: 'Engine Exhaust 1',
        engine__1_engine_exhaust_temperature_2: 'Engine Exhaust 2',
        engine__1_engine_coolant_temperature: 'Engine Coolant',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_temperature_1: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_scr_intake_temperature: 'SCR Intake',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_intake_temperature: 'Diesel Oxidation Catalyst Intake',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_outlet_temperature: 'Diesel Oxidation Catalyst Outlet',
        engine__1_ambient_air_temperature: 'Ambient Air',
        engine__1_engine_oil_temperature_1: 'Engine Oil',
        exhaust_emission_controller_aftertreatment_1_exhaust_temperature_1: 'Aftertreatment Exhaust',
        engine__1_engine_intake_manifold__1_pressure: 'Intake Manifold',
        engine__1_engine_air_filter_1_differential_pressure: 'Air Filter Differential',
        engine__1_engine_fuel_delivery_pressure: 'Fuel Delivery',
        engine__1_engine_oil_pressure_1: 'Oil',
        engine__1_aftertreatment_1_diesel_exhaust_fluid_doser_1_absolute_pressure: 'Exhaust Fluid Doser',
        engine__1_aftertreatment_1_diesel_particulate_filter_outlet_pressure: 'Diesel Particulate Filter Outlet',
        engine__1_aftertreatment_1_diesel_particulate_filter_differential_pressure: 'Diesel Particulate Filter Differential',
        engine__1_engine_intake_air_pressure: 'Engine Intake Air',
        engine__1_engine_oil_filter_differential_pressure: 'Engine Oil Filter Differential',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_differential_pressure: 'Diesel Oxidation Catalyst Differential',
        engine__1_barometric_pressure: 'Barometric Pressure',
        engine__1_engine_total_hours_of_operation: 'Engine Hours',
        engine__1_engine_total_fuel_used: 'Total Fuel Used',
        engine__1_aftertreatment_1_diesel_particulate_filter_time_since_last_active_regeneration:
          'Diesel Particulate Filter Time Since Last Active Regeneration',
        engine__1_engine_fuel_supply_estimated_remaining_distance: 'Range (fuel)',
      },
    },
  },
  label: 'label | labels',
  description: 'Description | Descriptions',
  engine: 'Engine',
  timelinechart: {
    relative: 'Relative view',
    absolute: 'Absolute view',
    filterEmpty: 'Hide days without any data',
    showDetails: 'Show data by hour',
    tableView: 'Table view',
    chartView: 'Chart view',
  },

  maintenance: {
    district: 'district | districts',
    fleetDetails: 'Fleet details',
    fleetComposition: 'Fleet composition',
    fleet: 'Fleet | Fleets',
    shiftPlan: 'Shift plan | Shift plans',
    shift: 'Shift | Shifts',
    selectFleet: 'Select fleet',
    selectShiftPlan: 'Select shift plan',
    selectShift: 'Select shift',
    selectShiftOccurance: 'Select Shift ocurrance',
    assetRequiresAttention: 'Asset with critical issues | Assets with critical issues',
    assetWithErrors: 'Asset with errors | Assets with errors',
    assetWithWarnings: 'Asset with warnings | Assets with warnings',
    assetInUnknownCondition: 'Asset in unknown condition | Assets in unknown condition',
    assetInGoodCondition: 'Asset in good condition | Assets in good condition',
    dataCoverage: 'Data coverage',
    priorityError: 'Critical issue | Critical issues',
    acknowledged: 'Acknowledge',
    fixed: 'fixed',
    context: 'context',
    engineSpeed: 'engine speed',
    cooleantLevel: 'coolant level',
    assetHealth: 'Asset Health',
    fuelType: 'Fuel Type',
    hourMeter: 'Hour Meter',
    risk: 'Risk',
    insights: 'Insights',
    active: 'Active',
    currentShift: 'Current Shift',
    currentDay: 'Current Day',
    previousShift: 'Previous Shift',
    previousDay: 'Previous Day',
    last24Hours: 'Last 24h',
    last7Days: 'Last 7 Days',
    last14Days: 'Last 14 Days',
    last21Days: 'Last 21 Days',
    last30Days: 'Last 30 Days',
    component: 'Component',
    start: 'Start Time',
    count: 'Internal Count',
    HealthExplorer: 'Maintenance',
    summaryByAssetType: 'Summary by asset type',
    summary: 'Summary',
    details: 'Details',
    recommendedAction: 'Recommended Action',
    insightsBySeverity: 'Insights by Severity',
    assetActivity: 'Asset Activities',
    insightsByOccurrence: 'Insights by Occurrence',
    insightsByCount: 'Insights by Count',
    insightsByDuration: 'Insights by Duration',
    badgeText: 'Last {x} Days',
    faultCount: 'Count',
    duration: 'Duration',
    variation: 'Change',
    since: 'since',
    selectedIssueNotInTimeframe: 'The selected insight is no longer available.',
    rawDataForInsight: 'Raw data for Insight',
    area: 'Area',
    fmiDescription: 'FMI Description',
    spareParts: 'Spare Part | Spare Parts',
    engine: {
      make: 'Make',
      unitNumber: 'Unit Number',
      softwareIdentificationFields: 'Number of Software Identification Fields',
      softwareIdentification: 'Software Identification',
      ecu: {
        partNumber: 'ECU Part Number',
        location: 'ECU Location',
        manufacturerName: 'ECU Manufacturer Name',
        hardwareVersion: 'ECU Hardware ID',
        constructionDate: 'ECU Date of Manufacture',
        serialNumber: 'ECU Serial Number',
        type: 'ECU Type',
      },
    },
    serviceAlerts: {
      nextService: 'Next Service',
      serviceDate: 'Service Date',
      interval: 'Time until Service',
      number: 'Number',
      quantity: 'Quantity',
      service: 'Service',
    },
  },
  severityLevels: {
    high: 'High',
    medium: 'Medium',
    low: 'Low',
  },
  severityCodes: {
    priority: 'Priority',
    red: 'Error',
    error: 'Error',
    yellow: 'Warning',
    warning: 'Warning',
    malfunction: 'Malfunction',
    protection: 'Protection',
    ok: 'Ok',
    nodata: 'NoData',
  },
  notifications: {
    title: 'notifications',
    priorityError: 'has {count} critical Issue | has {count} critical Issues',
    engineIssue: 'has {count} Engine Issue | has {count} Engine Issues',
    productionIssue: 'has {count} Production Issue | has {count} Production Issues',
    bomagError: 'has {count} red Bomag failure code | has {count} red Bomag failure codes',
    overspeedIssue: 'has an over-speeding event in {geofence} with speed {speed} for {duration} seconds between {startTime} & {endTime}',
    endOfList: 'That’s all your notifications from the last 7 days!',
    markAll: 'Mark all as read',
    subtitle: {
      unread: 'Unread Messages',
      all: 'All Messages',
    },
    tooltip: {
      viewUnread: 'view unread messages',
      viewAll: 'view all messages',
      markRead: 'mark as read',
      markUnread: 'mark as unread',
    },
  },
  dashboardInformationSettings: {
    default: 'No Logo',
    select: 'Select Logo',
  },
  errorStatisticsSettings: {
    select: 'Select Filter',
  },
  deleted: {
    assets: 'deleted asset | deleted assets',
  },
  showUnknowns: 'Filter unknown regions of a cycle',
  groupBy: 'Group By',
  unknownOEM: 'Unknown OEM',
  unknownModel: 'Unknown Model',
  unknownType: 'Unknown Type',
  noFilter: 'No Active Filter',
  selectedAssets: 'Selected Asset | Selected Assets',
  selectedAssetsInfo: 'All {x} assets has been selected',
  assetNotSelectedInfo: 'Please select atleast an Asset | Please select atleast 2 Assets',
  assetGroupNotSelectedInfo: 'Please Select an Option',
  pivotTable: {
    sourceGeofence: 'Loading area',
    targetGeofence: 'Dumping area',
    materialType: 'Material type',
    tonnage: 'Tonnage',
    noCycles: 'Cycles',
    sumDistance: 'Distance',
    avgDistance: 'Distance (∅)',
    sumDuration: 'Duration',
    avgDuration: 'Duration (∅)',
    tonnageKilometer: 'Tonnage per Kilometer',
    fuelPerTonnage: 'Fuel consumption per ton',
    fuelPerHour: 'Fuel consumption per hour',
    energyConsumptionPerTonnage: 'Net Energy Consumption per ton',
    energyConsumptionPerHour: 'Net energy consumption per hour',
    sourceAsset: 'Loading asset',
    targetAsset: 'Hauling asset',
    asset: 'Asset',
    nonTalpaAsset: 'Non-Talpa Asset',
  },
  advancedSearch: {
    hotkeyExplanations: {
      enter: 'to select',
      arrows: 'to navigate',
      esc: 'to exit search',
    },
  },
  marketplace: {
    productTypes: {
      all: 'All',
      app: 'Apps',
      bundle: 'Bundles',
    },
  },
  defaultApp: 'Default App',
  validationErrors: {
    required: 'This field is required.',
    requiredIf: 'This field is required.',
    minLength: 'Text should be longer',
  },
  durationFormatSettings: {
    title: 'Duration Format',
    label: 'Enable Decimal Format for Time-Based KPIs',
  },
  planning: {
    myCalendars: 'My calendars',
    createCalendar: 'Create Calendar',
    addSchedule: 'Add Schedule',
    ownerTypePlaceholder: 'Select Owner Type',
    ownerType: 'Owner Type',
    self: 'Self',
    ownerPlaceholder: 'Select Owner',
    createSchedule: 'Create Schedule',
    editSchedule: 'Edit Schedule',
    timeSettings: 'Time Settings',
    goals: 'Goals',
    scheduleTypePlaceholder: 'Select Schedule type',
    scheduleType: 'Schedule Type',
    recurringTask: 'Recurring Task',
    createShiftReport: 'Create Shift report',
    assignedAssets: 'Assigned Assets',
    allAssetsOfOwner: 'All assets of owner({x})',
    custom: 'Custom',
    scheduleStartDate: 'Schedule start date',
    freqency: 'Freqency',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    interval: 'Interval',
    daysInWeek: 'Days in the week',
    weekDays: {
      mo: 'MO',
      tu: 'TU',
      we: 'WE',
      th: 'TH',
      fr: 'FR',
      sa: 'SA',
      su: 'SU',
    },
    addGoal: 'Add Goal',
    createGoal: 'Create new performance Goal',
    editGoal: 'Edit performance Goal',
    assignedAssetsGoal: 'Assigned Assets for Goal',
    allAssetsOfSchedule: 'All assets of schedule({x})',
    rule: 'Rule | Rules',
    addRule: 'Add Rule',
    min: 'Min',
    max: 'Max',
    range: 'Range',
    below: 'Below',
    above: 'Above',
    createRule: 'Create rule',
    updateRule: 'Update rule',
    selectAssetDimension: 'Select AssetDimension',
    selectRuleType: 'Select Rule Type',
    ruleType: 'Rule Type',
    threshold: 'Threshold',
    more: '{x} more',
    reports: 'Reports',
    messages: {
      noCalendars: 'You dont have any calendars',
      noSchedules: 'No schedules in calendar',
      newCalendarCreated: 'Calendar {x} created successfully.',
      addNewSchedule: 'Should you need to add a schedule to the calendar?',
      removeGoal: 'Do you want to remove the goal <i>{x}</i> from the schedule ?',
      removeRule: 'Do you want to the remove rule on <i>{x}</i>',
    },
    timezoneOptions: {
      schedule: 'Schedule',
      local: 'Local',
      utc: 'UTC',
    },
    preview: 'Preview',
    previewMsg: 'Sample cards for {x} previous occurrences of schedule',
  },
  viewCountOfInstance: 'View y-axis in Count of instances',
  count: 'Count',
  cycleTable: {
    cycleID: 'cycleID',
    start: 'start',
    end: 'end',
    sourceAsset: 'source asset',
    targetAssetTalpaID: 'target asset',
    sourceGeofence: 'Source geofence',
    sourceAssetTalpaID: 'source asset',
    asset: 'asset',
    targetGeofence: 'Target geofence',
    materialType: 'material',
  },
  massflow: 'mass flow',
  none: 'none',
  activity: 'activity',
  extensions: {
    table: {
      title: 'My Extensions',
      name: 'Name',
      lastUpdated: 'Last Updated',
      status: 'Status',
      tool: 'Tools',
    },
  },
  loadmore: 'Load more',
  enginesManager: {
    engine: 'Engine',
    heading: 'Asset Engine Manager',
    nthEngineHeading: 'Engine {nth} manager',
    serialNumber: {
      label: 'Serial Number',
      validations: {
        required: 'Serial Number is required',
      },
    },
    ecu: {
      partNumber: 'ECU Part Number',
    },
    make: {
      name: 'Make',
      create: 'Create Make',
    },
    model: {
      name: 'Model',
      create: 'Create Model',
    },
    energy: {
      source: 'Energy Source',
    },
    addEngine: 'Add Engine',
    addAnotherEngine: 'Add Another Engine',
    updateEngine: 'Update Engine',
    saveEngine: 'Save Engine',
  },
  historicalAssetHealth: {
    title: 'Asset Health',
    mtbf: 'Mean Time Between Faults',
    mttr: 'Mean Time to Resolution',
    tooltip: {
      sickPeriods: 'Fault Period',
      errors: 'Fault | Faults',
      warnings: 'Warning | Warnings',
      healthyPeriod: {
        info: `<strong>No faults</strong> indicate that the machine is operating without any faults mentioned below.`,
      },
      sickPeriod: {
        info: `<strong>Has faults</strong> indicates the machine's operating time with triggered engine faults in combination with the FMI codes 0, 1, 16 or 18.`,
      },
      unknownPeriod: { info: `<strong>No Information</strong> indicate unavailability of data.` },
      mttbs: 'MTBS (Monthly) = Operating time / Total duration of fault periods',
      mttrs: 'MTTRS (Monthly) = Total duration of fault periods / count of fault periods',
    },
    sickPeriods: {
      tooltip: {
        duration: 'Duration: {duration}',
      },
    },
    status: {
      healthy: 'No faults',
      sick: 'Has faults',
      unknown: 'No Information',
    },
    sickPeriod: 'Fault Details',
    errorCode: 'Code # {errorCode}',
    sickHours: 'Fault Period',
    operationTime: 'Operating time',
  },
  unknownUsergroup: 'Unknown User Group',
  shortMinute: 'Min',
  analytics: 'Analytics',
}
